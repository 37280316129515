import { serverTimestamp } from 'firebase/firestore';
import React from 'react';
import { auth, db } from '../../services/firebase';
import { IJobType, IProject } from './types';

export const projectState = {
  activeJob: null,
  activeProject: null,
  createProject: async (name: string, jobTypes: string[]) => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      return Promise.reject('No active logged in user found.');
    }
    const timestamp = serverTimestamp();
    const jobBatch = db.batch();
    const projectRef = db.collection('projects').doc();
    jobBatch.set(projectRef, {
      createdAt: timestamp,
      name,
      uid: currentUser.uid,
      updatedAt: timestamp
    });
    jobTypes.forEach(jobType => {
      const jobTypeRef = projectRef.collection('job-types').doc();
      jobBatch.set(jobTypeRef, {
        createdAt: timestamp,
        type: jobType,
        uid: currentUser.uid,
        updatedAt: timestamp
      });
    });
    return jobBatch.commit();
  },
  getRecentProjects: async (): Promise<IProject[]> => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      return Promise.reject('No active logged in user found.');
    }
    const projectsRef = db.collection('projects');
    const projectsSnapshot = await projectsRef
      .where('uid', '==', currentUser.uid)
      .orderBy('updatedAt', 'desc')
      .limit(3)
      .get();
    const finalResultPromise = projectsSnapshot.docs.map(async document => {
      const jobTypesSnapshot = await document.ref.collection('job-types').get();
      const jobTypes = jobTypesSnapshot.docs.map(jt => ({
        ...jt.data(),
        id: jt.id
      }));
      return { ...document.data(), jobTypes, id: document.id } as IProject;
    });
    return Promise.all(finalResultPromise);
  },
  searchProject: async (text: string): Promise<IProject[]> => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      return Promise.reject('No active logged in user found.');
    }
    const projectsRef = db.collection('projects');
    const projectQuerySnapshot = await projectsRef
      .orderBy('name')
      .startAt(text)
      .endAt(`${text}\uf8ff`)
      .get();
    const finalResultPromise = projectQuerySnapshot.docs.map(async document => {
      const jobTypesSnapshot = await document.ref.collection('job-types').get();
      const jobTypes = jobTypesSnapshot.docs.map(jt => ({
        ...jt.data(),
        id: jt.id
      }));
      return { ...document.data(), jobTypes, id: document.id } as IProject;
    });
    return Promise.all(finalResultPromise);
  },
  setActiveProjectAndJobType: () => void 0
};

const ProjectContext = React.createContext<IProjectContext>(projectState);
export interface IProjectContext {
  createProject: (name: string, jobTypes: string[]) => Promise<void>;
  getRecentProjects: () => Promise<IProject[]>;
  setActiveProjectAndJobType: (project: IProject, jobType: IJobType) => void;
  searchProject: (text: string) => Promise<IProject[]>;
  activeProject: IProject | null;
  activeJob: IJobType | null;
}

export default ProjectContext;
