import React, { useState } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { OverlayTrigger, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Paginations from './Pagination';
import SnippetDetails from './SnippetDetails';
import ReactTooltip from 'react-tooltip';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { storage } from '../../services/firebase';

const DownloadTD = styled.td`
  cursor: pointer;
  text-align: center;
  color: var(--blue);
  font-weight: 500;
`;
const TR = styled.tr<{ color: string }>`
  background-color: rgba(${({ color }) => color}, 1) !important;
  position: sticky;
  top: 90px;
  z-index: 1;
`;
const TRB = styled.tr<{ color: string }>`
  &:nth-of-type(odd) {
    background-color: rgba(${({ color }) => color}, 0.3) !important;
  }
  &:nth-of-type(even) {
    background-color: rgba(${({ color }) => color}, 0.15) !important;
  }
  & td {
    border: 1px solid #fff !important;
    font-size: 14px;
  }
`;
const TH = styled.th`
  border: 1px solid #fff !important;
  border-bottom: 2px solid #fff !important;
  color: #fff;
  vertical-align: middle;
`;
const TD = styled.td`
  word-break: break-all;
  font-size: 13px;
  width: 40%;
  em {
    font-style: normal;
    font-weight: 700;
  }
  & > div {
    font-size: 13px;
    min-height: 100px;
  }
  & span {
    font-weight: 500;
    color: #005fbc;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
  }
`;

const doStorageDownload = R.contains(R.__, ['tceq_novell']);
const getStorageRef = R.cond<string, ReturnType<typeof getStorage> | null>([
  [
    R.equals('tceq_novell'),
    R.always(
      getStorage(undefined, `gs://${process.env.REACT_APP_STORAGE_BUCKET_NOVELL}`)
    ),
  ],
  [R.T, R.always(null)],
]);

interface IContent {
  environmenData: any;
  queryText: any;
  sentimentAnalyzer: (keyword: any, from: number) => void;
  setIsLoading: any;
  color: any;
}

const TableContent: React.FC<IContent> = (props) => {
  const [downloadTriggers, setDownloadTriggers] = useState<string[]>([]);

  return (
    <>
      <Table striped bordered hover>
        <thead>
          <TR color={props.color}>
            <TH>Rank</TH>
            <TH style={{ width: '100px' }}>Sentiment Score</TH>
            <TH>Text Snippet</TH>
            <TH>Entity's File</TH>
            <TH>Document</TH>
          </TR>
        </thead>
        <tbody>
          {typeof props.environmenData.data !== 'undefined' &&
            props.environmenData.data.length > 0 &&
            props.environmenData.data.map((record: any, index: any) => (
              <TRB key={record.rank} color={props.color}>
                <td>#{record.rank}</td>
                <td>{record.sentiment_score}</td>
                <TD>
                  <SnippetDetails
                    details={
                      record.snippet
                        ? record.snippet.replace(`/[^\x00-\x7F]/g`, '')
                        : ''
                    }
                  />
                </TD>
                <td>{record.entity_name}</td>
                <OverlayTrigger
                        placement="bottom"
                        key={index}
                        overlay={<ReactTooltip effect="solid" />}
                      >
                <DownloadTD
                  onClick={() => {
                    if (doStorageDownload(record.doc_url)) {
                      const storageRef = getStorageRef(record.entitySource);
                      if (R.isNil(storageRef)) {
                        return toast.error(`Oops, could not find the file.`);
                      }
                      setDownloadTriggers(
                        R.append(index + 1, downloadTriggers)
                      );
                      return getDownloadURL(ref(storage, record.fileName))
                        .then((url) => window.open(url, '_blank', 'noopener,noreferrer'))
                        .catch((err) => toast.error(err.message))
                        .finally(() =>
                          setDownloadTriggers(R.without([index + 1], downloadTriggers))
                        );
                    }
                    if (!record.doc_url) {
                      return toast.warn(
                        <span>
                          No associated file was found for the content id{' '}
                          <b>{record.doc_source}</b>
                        </span>
                      );
                    }
                    window.open(record.doc_url, '_blank');
                  }}
                >
                  <a href={record.doc_url} data-tip="Click to download">{record.doc_source}</a>
                </DownloadTD>
                </OverlayTrigger>
              </TRB>
            ))}
        </tbody>
      </Table>

      <Paginations
        isDisabled={false}
        isFirstPage={
          (props.environmenData &&
            props.environmenData.records_num_from === 0) ||
          false
        }
        isLastPage={
          (props.environmenData &&
            props.environmenData.records_num_from +
              props.environmenData.records_num_show >=
              props.environmenData.total_records) ||
          false
        }
        totalRecords={
          (props.environmenData && props.environmenData.total_records) || 0
        }
        onPreviousClick={() => {
          if (!props.environmenData) {
            return;
          }
          const currentFrom = props.environmenData.records_num_from;
          const currentSize = props.environmenData.records_num_show;
          props.setIsLoading(true);
          return props.sentimentAnalyzer(
            props.environmenData.sorting,
            currentFrom - currentSize
          );
        }}
        onNextClick={() => {
          if (!props.environmenData) {
            return;
          }
          const currentFrom = props.environmenData.records_num_from;
          const currentSize = props.environmenData.records_num_show;
          return props.sentimentAnalyzer(
            props.environmenData.sorting,
            currentFrom + currentSize
          );
        }}
      />
    </>
  );
};
export default TableContent;
