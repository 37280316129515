import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import { Table } from 'react-bootstrap';
import { ResultContainer } from '../custom-styled-components/CommonStyledComponents';
import { Emission, TableContainer, TableHeading, TD, TH } from '../custom-styled-components/EmissionEventStyledComponents';
import { IEmissionsEvent, IChartData, IChartOptions } from '../../types/emissionEvent';
import { numberFormat } from '../../util/regexFormat';
import { tableColumns, tableEmissionColumns } from '../../util/emissionevent.util';

const EmissionsEvent: React.FC<IEmissionsEvent> = ({ entity, result }) => {
  const [emissionEvents, setEmissionEvents] = useState<IChartData | null>(null);
  const [quantityReleased, setQuantityReleased] = useState<IChartData | null>(null);
  const [dataOptions, setDataOptions] = useState<IChartOptions | null>(null);

  useEffect(() => {
    if (!result) { return; }

    const eventsLabels = result.events_graph.map((ele: any) => ele.year);
    const eventsData = result.events_graph.map((ele: any) => ele.events);
    const quantityLabels = result.compounds_graph.map((ele: any) => ele.year);
    const quantityData = result.compounds_graph.map((ele: any) => ele.pounds);

    setEmissionEvents({
      labels: eventsLabels,
      datasets: [
        {
          label: 'Emission Events',
          fill: false,
          lineTension: 0.1,
          backgroundColor: Emission.emissionEvents.background,
          borderColor: Emission.emissionEvents.border,
          pointBorderColor: Emission.emissionEvents.border,
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: Emission.emissionEvents.border,
          pointHoverBorderColor: Emission.emissionEvents.pointHoverBorder,
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: eventsData,
        },
      ],
    });

    setQuantityReleased({
      labels: quantityLabels,
      datasets: [
        {
          label: 'Quantity Released (pounds)',
          fill: false,
          lineTension: 0.1,
          backgroundColor: Emission.quantityReleased.background,
          borderColor: Emission.quantityReleased.border,
          pointBorderColor: Emission.quantityReleased.border,
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: Emission.quantityReleased.border,
          pointHoverBorderColor: Emission.quantityReleased.pointHoverBorder,
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: quantityData,
        },
      ],
    });

    setDataOptions({
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        callbacks: {
          label: (value: any, text: any) => {
            const xLabel = text.datasets[value.datasetIndex].label;
            const yLabel = numberFormat(value.yLabel);
            return `${xLabel}: ${yLabel}`;
          },
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              callback: (value: any) => numberFormat(value),
            },
          },
        ],
      },
    });
  }, [result]);

  return (
    <ResultContainer>
      <Row>
        <Col md={6}>
          <Line data={emissionEvents} options={dataOptions} />
        </Col>
        <Col md={6}>
          <Line data={quantityReleased} options={dataOptions} />
        </Col>
      </Row>
      <TableContainer>
        <Row>
          <Col md={6}>
            <TableHeading>
              <h6>Rank of Emission Events</h6>
            </TableHeading>
            <Table bordered hover>
              <thead>
                <tr>
                  {tableColumns.map((col) => (
                    <TH key={col.key}>{col.heading}</TH>
                  ))}
                </tr>
              </thead>
              <tbody>
                {result.events_table.map((ele: any) => (
                  <tr key={'Events' + ele.rn}>
                    {tableColumns.map((col) => {
                      let cellValue;

                      if (col.key === 'facility') {
                        cellValue = ele.name;
                      } else if (col.key === 'events') {
                        cellValue = ele.event.toLocaleString(navigator.language, {
                          minimumFractionDigits: 0,
                        });
                      } else {
                        cellValue = ele[col.key];
                      }

                      return (
                        <TD key={col.key}>
                          {entity.value === ele.rn ? <b>{cellValue}</b> : cellValue}
                        </TD>
                      );
                    })}
                  </tr>
                ))}
              </tbody>

            </Table>
          </Col>
          <Col md={6}>
            <TableHeading>
              <h6>Rank of Quantity Released</h6>
            </TableHeading>
            <Table bordered hover>
              <thead>
                <tr>
                  {tableEmissionColumns.map((col) => (
                    <TH key={col.key}>{col.heading}</TH>
                  ))}
                </tr>
              </thead>
              <tbody>
                {result.compounds_table.map((ele: any) => (
                  <tr key={'Pounds' + ele.rn}>
                    {tableEmissionColumns.map((col) => {
                      // Mapping column keys to actual object properties
                      let cellValue;

                      if (col.key === 'facility') {
                        cellValue = ele.name; // Facility is stored as `name`
                      } else if (col.key === 'pounds') {
                        cellValue = ele.pounds.toLocaleString(navigator.language, {
                          minimumFractionDigits: 2,
                        }); // Formatting for pounds
                      } else {
                        cellValue = ele[col.key]; // Fallback for correctly named keys
                      }

                      return (
                        <TD key={col.key}>
                          {entity.value === ele.rn ? <b>{cellValue}</b> : cellValue}
                        </TD>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </TableContainer>
    </ResultContainer>
  );
};

export default EmissionsEvent;
