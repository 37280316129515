import * as R from 'ramda';
import * as CryptoJS from 'crypto-js';

import { Alert, Button, OverlayTrigger } from 'react-bootstrap';
import { Col, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { IAIEarth, IAir, IJob, IMap } from '../../store/aiearth/types';
import { IElasticResult, ISearchRecord, dummyRecord } from '../../types/search';
import React, { Fragment, useState, useEffect } from 'react';
import { ceresApp, iv, key as secKey, searchFavorite } from '../../services/firebase';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import {
  updateAIEarth,
  updateAir,
  updateJob,
  updateMap,
  resetJob,
  resetAir,
  resetAIEarth,
} from '../../store/aiearth/action';
import { useDispatch, useSelector } from 'react-redux';
import { centroid, multiLineString, multiPolygon, polygon } from '@turf/turf';
import AIEarthAirTab from './AIEarthAirTab';
import AiEarthMap from './map';
import AirTabDrawer from './AirTabDrawer';
import { CeresLogger } from '../../logger';
import { ChevronLeft, Download, ChevronsLeft, ChevronsRight } from 'react-feather';
import DataTable from 'react-data-table-component';
import { IAuthContext } from '../../context/auth/auth-context';
import { IDatabase } from '../../store/aiearth/types';
import Jobs from './Jobs';
import JobsAdjoiningProperties from './JobsAdjoiningProperties';
import JobsEditAddress from './JobsEditAddress';
import JobsIdentifyManagers from './JobsIdentifyManagers';
import JobsIdentifyUsers from './JobsIdentifyUsers';
import ListSubheader from '@material-ui/core/ListSubheader';
import ProgramQueryDocDrawer from './ProgramQueryDocDrawer';
import PropTypes from 'prop-types';
import RecordMarker from './map/RecordMarker';
import RecordNavigation from './map/RecordNavigation';
import { Scrollbars } from 'react-custom-scrollbars';
import SearchPagination from './SearchPagination';
import { TIERS } from '../../types/tiers';
import { VariableSizeList } from 'react-window';
import axios from 'axios';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import withAuthContext from '../../context/auth/AuthConsumer';
import RoomIcon from '@material-ui/icons/Room';
import MapsFinding from './MapsFinding';
import IconButton from '@material-ui/core/IconButton';
import StarIcon from '@material-ui/icons/Star';
import ReactTooltip from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';
import { API_BASE_URL, API_AUTH_HEADERS } from '../../util/api.util';
import { CommonBgColor } from '../custom-styled-components/CommonStyledComponents';
import { getStorage, ref, StorageReference } from 'firebase/storage';
// tslint:disable-next-line:no-var-requires
const googleMapReactUtil = require('google-map-react/utils');

interface IAiEarthContainer {
  result: IElasticResult<ISearchRecord> | null;
  programQueryResult: ISearchRecord[];
  programQueryMapResult: ISearchRecord[];
  programQueryResultCount: any;
  programQueryEPNResult: any;
  error?: any;
  showPanel: any;
  setShowPanel: any;
  showDocPanel: any;
  setShowDocPanel: any;
  isLoading: any;
  plotLatLong: any;
  onPreviousClick: () => void;
  onNextClick: () => void;
  programQueryNextClick: () => void;
  programQueryPreviousClick: () => void;
  handleSort: () => void;
  SearchForm: any;
  ProgramQuery: React.StatelessComponent;
  currentQueryText: string;
  showProgramQueryPanel: any;
  setShowProgramQueryPanel: any;
  showProgramQueryDocPanel: any;
  setShowProgramQueryDocPanel: any;
  activeMarkerRecord: any;
  setActiveMarkerRecord: any;
  boundingBox?: any;
  setBoundingBox?: any;
  onRectDrag?: any;
  coords?: any;
  setKeyEvent?: any;
  getPageRoute: any;
  showRecordsLoader: boolean;
  chemPlantResults: any;
  isChemPlantLoading: boolean;
  setProgramQueryEPNResult: any;
}

export const isValidLatLong = (lat: number, lng: number) => {
  return lat !== 0 && lng !== 0 && lat !== null && lng !== null;
};
const getUniqueSearchEntityRecords = (records: ISearchRecord[]) => {
  return R.compose<ISearchRecord[], ISearchRecord[], ISearchRecord[]>(
    R.uniqBy(R.prop('entityNumber')),
    R.filter((record: ISearchRecord) => !!record && !!record.entityNumber)
  )(records);
};
const RNDataContainer: any = styled.div`
  padding: 5px 10px;
  height: calc(100vh - 180px);
  overflow: auto;
  background-color: #f1f1f1;
`;

const ButtonContainer: any = styled.div`
  display: flex;
  padding: 5px 10px;
  background-color: #6c757d;
  flex-direction: row-reverse;
`;

const PanelNavigation = styled.div`
  display: flex;
  padding: 5px 10px;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
`;

const PanelContent = styled.div`
  background-color: #fff;
  padding: 2px;
  height: 100%;
  width: 100%;
`;
const SourceCell = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
`;

const CustomButton = styled(Button)`
  padding: 5px !important;
  height: 37px;
  line-height: 0.1;
`;

const ReportDownload = styled(Button)`
  margin-top: 40px;
  font-size: 16px;
`;
const HazardMaps = styled(Button)`
  margin-top: 40px;
  font-size: 16px;
`;

const JobTasksContainer = styled(RNDataContainer)`
  display: flex;
  flex-direction: column;
`;

const TasksContainer = styled.div`
  transition: background-color 0.5s ease;
  display: flex;
  flex-direction: column;
`;

const DocPreviewContainer = styled(RNDataContainer)`
  background-color: #fff;
`;

const PreviewButtonContainer = styled(ButtonContainer)`
  background-color: #ea5647;
`;

const GridContainer = styled.div`
  padding: 5px;
  height: calc(100vh - 195px);
  /* overflow-x: scroll;
  & > * {
    overflow-x: initial;
  } */
`;

const StyledTabs = styled.div`
  height: calc(100vh - 115px);
  margin: 75px 5px 0;
  & a {
    font-size: 15px;
    padding: 8px 5px;
  }
`;
const List = styled.span`
  cursor: pointer;
  &:hover * {
    color: #005fbc;
    text-decoration: underline;
  }
`;

const StyledDataTable = styled(DataTable)`
  & .rdt_TableCell {
    font-size: 10px;
    padding: 3px;
    width: 9rem;
    text-overflow: ellipsis;
  }
  & .rdt_TableCol {
  }
  /* & .rdt_TableBody {
    overflow-y: initial;
  } */
  & .rdt_TableRow,
  .rdt_TableHeadRow {
    min-height: 36px;
  }
  & .rdt_TableRow,
  .rdt_TableCol {
    padding: 0 10px;
  }
`;
const ContentTD = styled.p`
  && {
    padding: 0.75em;
  }
  em {
    font-style: normal;
    font-weight: 700;
  }
`;
const CollapsBar = styled.button`
  position: absolute;
  bottom: -26px;
  width: 100%;
  background-color: #fff;
  padding: 5px;
  border: 0;
  left: 0;
  color: var(--blue);
  font-size: 16px;
  font-weight: 500;
  z-index: 999;
  &:focus {
    outline: 0px auto -webkit-focus-ring-color;
  }
`;
const FavIconBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  background-color: rgb(0, 95, 188);
  margin-bottom: 10px;
`;
const RecordNavigationContainer = styled.div`
  background: rgba(108, 117, 125, 0.5);
  padding: 8px;
  padding-bottom: 0;
  /* display: flex; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const SpinnerContainer = styled.div `
  text-align: center;
  margin-top: 2rem;
`;

const OpenCollarse = styled.button`
  position: absolute;
  bottom: 0;
  z-index: 999;
  border: 0;
  background-color: rgba(244, 134, 12, 0.7);
  top: 0;
  left: 0;
  height: 45px;
  margin: auto;
  color: #000;
  padding: 0;
  &:focus {
    outline: 0px auto -webkit-focus-ring-color;
  }
`;

const LISTBOX_PADDING = 8;

function renderRow(renderRowProps: any) {
  const { data, index, style } = renderRowProps;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}
const OuterElementContext = React.createContext({});
const OuterElementType = React.forwardRef<HTMLDivElement, any>((OuterElementprops, forwardedRef) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={forwardedRef} {...OuterElementprops} {...outerProps} />;
});

function useResetCache(data: any) {
  const cacheRef = React.useRef<any>(null); // Renamed from 'ref' to 'cacheRef'
  React.useEffect(() => {
    if (cacheRef.current) {
      cacheRef.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return cacheRef;
}

// Adapter for react-window
const SelectComponent: any = React.forwardRef(function ListboxComponent(props: any, forwardedRef: any) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: any) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={forwardedRef}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

SelectComponent.propTypes = {
  children: PropTypes.node,
};

/**
 * check if the file needs to be downloaded from storage bucket
 * or rely on the file url, directly.
 */

const AiEarthContainer: React.FC<
  IAiEarthContainer & {
    context: IAuthContext;
  }
> = (props) => {
  const records = props.result ? props.result.records : [];
  const context = props.context;
  const dispatch = useDispatch();
  const { SearchForm } = props;
  const { ProgramQuery } = props;
  const [isFavLoading, setIsFavLoading] = useState(false);
  const [showNavigation, setShowNavigation] = useState(false);
  const [downloadTriggers, setDownloadTriggers] = useState<string[]>([]);
  const [myFavList, setMyFavList] = useState<any[]>([]);
  const [gMap, setGMap] = useState<any>();
  const [gMaps, setGMaps] = useState<any>();
  const [getStreetLocation, setStreetLocation] = useState<any>();
  const [rectangle, setRectangle] = useState<any>();
  const [activeTabDrawer, setActiveTabDrawer] = useState<any>('permits');
  const [relatedDocument, setRelatedDocument] = useState<ISearchRecord | null>(null);
  const [zoomLevel, setZoomLevel] = useState<any>();
  const [getAutoScroll, setAutoScroll] = useState<any>(false);
  const store: any = useSelector<any>((state): any => state);
  const mapState: IMap = store.map;
  const [getJobReport, setJobReport] = useState<boolean>(false);
  const [isDownload, setDownload] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<any>('');
  const [selectedChemPlant, setSelectedChemPlant] = useState<any>({});
  const [chemPlantsEpnResult, setChemPlantsEpnResult] = useState<any>([]);

  const databaseState: IDatabase = store.database;
  const jobState: IJob = store.job;
  const aiEarthState: IAIEarth = store.aiearth;
  const airState: IAir = store.air;

  const uniqueEntityRecords = getUniqueSearchEntityRecords(records);
  if (props.boundingBox) {
    const mapZoom = gMap.getZoom();
    const mapCenter = gMap.getCenter();
    const centWCord = gMap.getProjection().fromLatLngToPoint(mapCenter);
    const centPix = new gMaps.Point(centWCord.x * Math.pow(2, mapZoom), centWCord.y * Math.pow(2, mapZoom));
    let ne = new gMaps.Point(centPix.x + 35, centPix.y + 35);
    let sw = new gMaps.Point(centPix.x - 35, centPix.y - 35);
    ne.x = ne.x / Math.pow(2, mapZoom);
    ne.y = ne.y / Math.pow(2, mapZoom);
    sw.x = sw.x / Math.pow(2, mapZoom);
    sw.y = sw.y / Math.pow(2, mapZoom);
    ne = gMap.getProjection().fromPointToLatLng(ne);
    sw = gMap.getProjection().fromPointToLatLng(sw);
    if (!rectangle.getBounds()) {
      if (databaseState.boundingBox) {
        const coOrds = databaseState.boundingBox.split(',');
        rectangle.setBounds({
          north: parseFloat(coOrds[1]),
          south: parseFloat(coOrds[3]),
          east: parseFloat(coOrds[0]),
          west: parseFloat(coOrds[2]),
        });
      } else {
        rectangle.setBounds({
          north: sw.lat(),
          south: ne.lat(),
          east: ne.lng(),
          west: sw.lng(),
        });
      }
    }
    rectangle.setMap(gMap);
  } else if (rectangle) {
    rectangle.setMap(null);
  }

  const getCenterWithOffset = (lat: number, lng: number, offsetX: number, offsetY: number) => {
    if (!gMap || !gMaps) {
      return;
    }
    const currentMapProjection = gMap.getProjection();
    const point = currentMapProjection.fromLatLngToPoint(new gMaps.LatLng(lat, lng));
    // point.x = point.x + offsetX;
    // point.y = point.y + offsetY;
    const newCenterLatLng = currentMapProjection.fromPointToLatLng(point);
    return { lat: newCenterLatLng.lat(), lng: newCenterLatLng.lng() };
  };

  const setSubjectPropertyEsaData = (subjectPropertyData: any, type: string = 'astm') => {
    if (subjectPropertyData) {
      if (mapState.subjectPropertyShowLayer) {
        mapState.subjectPropertyShowLayer.forEach((feature: any) => {
          mapState.subjectPropertyShowLayer.remove(feature);
        });
      }
      if (mapState.adjoiningPropertyLayer) {
        mapState.adjoiningPropertyLayer.forEach((feature: any) => {
          mapState.adjoiningPropertyLayer.remove(feature);
        });
      }
      if (mapState.waterWellsPointer) {
        mapState.waterWellsPointer.forEach((feature: any) => {
          mapState.waterWellsPointer.remove(feature);
        });
      }
      if (mapState.monitorWellsPointer) {
        mapState.monitorWellsPointer.forEach((feature: any) => {
          mapState.monitorWellsPointer.remove(feature);
        });
      }
      if (mapState.soilsPropertyLayer) {
        mapState.soilsPropertyLayer.forEach((feature: any) => {
          mapState.soilsPropertyLayer.remove(feature);
        });
      }
      const getType = (subjectPropJson: any) => {
        if (subjectPropJson.type === 'Polygon') {
          return polygon(subjectPropJson.coordinates);
        } else if (subjectPropJson.type === 'MultiPolygon') {
          return multiPolygon(subjectPropJson.coordinates);
        } else if (subjectPropJson.type === 'MultiLineString') {
          return multiLineString(subjectPropJson.coordinates);
        }
      };
      if (
        subjectPropertyData.subject_property ||
        subjectPropertyData.one_mile_buffer ||
        subjectPropertyData.half_mile_buffer ||
        subjectPropertyData.quarter_mile_buffer ||
        subjectPropertyData.regulated_entities_boundaries ||
        subjectPropertyData.regulated_entities_point
      ) {
        let polygonObj: any = {};
        const oneMileBuffer = subjectPropertyData.one_mile_buffer;
        if (
          oneMileBuffer &&
          typeof oneMileBuffer.features !== 'undefined' &&
          Object.keys(oneMileBuffer.features).length > 0 &&
          type === 'astm'
        ) {
          mapState.subjectPropertyShowLayer.addGeoJson(subjectPropertyData.one_mile_buffer);
        }
        if (
          subjectPropertyData.half_mile_buffer &&
          typeof subjectPropertyData.half_mile_buffer.features !== 'undefined' &&
          Object.keys(subjectPropertyData.half_mile_buffer.features).length > 0 &&
          type === 'astm'
        ) {
          mapState.subjectPropertyShowLayer.addGeoJson(subjectPropertyData.half_mile_buffer);
        }
        if (
          subjectPropertyData.quarter_mile_buffer &&
          typeof subjectPropertyData.quarter_mile_buffer.features !== 'undefined' &&
          Object.keys(subjectPropertyData.quarter_mile_buffer.features).length > 0 &&
          (type === 'astm' || type === 'topos' || type === 'oilgas' || type === 'water' || type === 'monitor')
        ) {
          mapState.subjectPropertyShowLayer.addGeoJson(subjectPropertyData.quarter_mile_buffer);
        }
        if (
          subjectPropertyData.regulated_entities_boundaries &&
          typeof subjectPropertyData.regulated_entities_boundaries.features !== 'undefined' &&
          typeof subjectPropertyData.regulated_entities_boundaries.features[0].geometry !== 'undefined' &&
          Object.keys(subjectPropertyData.regulated_entities_boundaries.features).length > 0 &&
          type === 'astm'
        ) {
          mapState.subjectPropertyShowLayer.addGeoJson(subjectPropertyData.regulated_entities_boundaries);
        }
        if (
          subjectPropertyData.regulated_entities_boundaries &&
          typeof subjectPropertyData.regulated_entities_boundaries.features !== 'undefined' &&
          typeof subjectPropertyData.regulated_entities_boundaries.features[0].geometry !== 'undefined' &&
          Object.keys(subjectPropertyData.regulated_entities_boundaries.features).length > 0 &&
          type === 'vec'
        ) {
          const boundaryJson = getFilterInfo(subjectPropertyData.regulated_entities_boundaries.features);
          if (Object.keys(boundaryJson).length > 0) {
            const regulatedEntityBoundary = {
              type: 'FeatureCollection',
              features: boundaryJson,
            };
            mapState.subjectPropertyShowLayer.addGeoJson(regulatedEntityBoundary);
          }
        }
        if (
          subjectPropertyData.regulated_entities_point &&
          typeof subjectPropertyData.regulated_entities_point.features !== 'undefined' &&
          typeof subjectPropertyData.regulated_entities_point.features[0].geometry !== 'undefined' &&
          Object.keys(subjectPropertyData.regulated_entities_point.features).length > 0 &&
          type === 'astm'
        ) {
          mapState.subjectPropertyShowLayer.addGeoJson(subjectPropertyData.regulated_entities_point);
          markerJson(subjectPropertyData.regulated_entities_point.features);
          jobState.mapFindings = setMapFindings(subjectPropertyData.regulated_entities_point.features);
          jobState.toolTipPoint = markerJson(subjectPropertyData.regulated_entities_point.features);
          dispatch(updateJob(jobState));
        }
        if (
          subjectPropertyData.regulated_entities_point &&
          typeof subjectPropertyData.regulated_entities_point.features !== 'undefined' &&
          typeof subjectPropertyData.regulated_entities_point.features[0].geometry !== 'undefined' &&
          Object.keys(subjectPropertyData.regulated_entities_point.features).length > 0 &&
          type === 'vec'
        ) {
          const filterData = getFilterInfo(subjectPropertyData.regulated_entities_point.features);
          jobState.mapFindings = setMapFindings(filterData);
          jobState.toolTipPoint = markerJson(filterData);
          dispatch(updateJob(jobState));
          if (Object.keys(filterData).length > 0) {
            const regulatedEntityPoint = {
              type: 'FeatureCollection',
              features: filterData,
            };
            mapState.subjectPropertyShowLayer.addGeoJson(regulatedEntityPoint);
          }
        }
        if (
          subjectPropertyData.vec_entities_boundaries &&
          typeof subjectPropertyData.vec_entities_boundaries.features !== 'undefined' &&
          Object.keys(subjectPropertyData.vec_entities_boundaries.features).length > 0 &&
          type === 'vec'
        ) {
          mapState.subjectPropertyShowLayer.addGeoJson(subjectPropertyData.vec_entities_boundaries);
        }
        if (
          subjectPropertyData.vec_entities_point &&
          typeof subjectPropertyData.vec_entities_point.features !== 'undefined' &&
          Object.keys(subjectPropertyData.vec_entities_point.features).length > 0 &&
          type === 'vec'
        ) {
          mapState.subjectPropertyShowLayer.addGeoJson(subjectPropertyData.vec_entities_point);
        }
        if (
          subjectPropertyData.thirty_feet_buffer &&
          typeof subjectPropertyData.thirty_feet_buffer.features !== 'undefined' &&
          Object.keys(subjectPropertyData.thirty_feet_buffer.features).length > 0 &&
          type === 'vec'
        ) {
          mapState.subjectPropertyShowLayer.addGeoJson(subjectPropertyData.thirty_feet_buffer);
        }
        if (
          subjectPropertyData.vec_crossgradient_buffer &&
          typeof subjectPropertyData.vec_crossgradient_buffer.features !== 'undefined' &&
          Object.keys(subjectPropertyData.vec_crossgradient_buffer.features).length > 0 &&
          type === 'aerial'
        ) {
          mapState.subjectPropertyShowLayer.addGeoJson(subjectPropertyData.vec_crossgradient_buffer);
        }
        if (
          subjectPropertyData.vec_downgradient_buffer &&
          typeof subjectPropertyData.vec_downgradient_buffer.features !== 'undefined' &&
          Object.keys(subjectPropertyData.vec_downgradient_buffer.features).length > 0 &&
          type === 'vec'
        ) {
          mapState.subjectPropertyShowLayer.addGeoJson(subjectPropertyData.vec_downgradient_buffer);
        }
        if (
          subjectPropertyData.vec_upgradient_buffer &&
          typeof subjectPropertyData.vec_upgradient_buffer.features !== 'undefined' &&
          Object.keys(subjectPropertyData.vec_upgradient_buffer.features).length > 0 &&
          type === 'vec'
        ) {
          mapState.subjectPropertyShowLayer.addGeoJson(subjectPropertyData.vec_upgradient_buffer);
        }
        if (
          subjectPropertyData.vec_angel &&
          typeof subjectPropertyData.vec_angel.features !== 'undefined' &&
          Object.keys(subjectPropertyData.vec_angel.features).length > 0 &&
          type === 'vec'
        ) {
          mapState.subjectPropertyShowLayer.addGeoJson(subjectPropertyData.vec_angel);
        }
        if (
          subjectPropertyData.contours &&
          typeof subjectPropertyData.contours.features !== 'undefined' &&
          subjectPropertyData.contours.features !== null &&
          Object.keys(subjectPropertyData.contours.features).length > 0 &&
          type === 'vec'
        ) {
          mapState.subjectPropertyShowLayer.addGeoJson(subjectPropertyData.contours);
        }
        if (
          subjectPropertyData.oil_gas_pipe_lines &&
          subjectPropertyData.oil_gas_pipe_lines.features &&
          type === 'oilgas'
        ) {
          mapState.pipeLinesJobMaps.addGeoJson(subjectPropertyData.oil_gas_pipe_lines);
        }
        if (subjectPropertyData.oil_gas_points && subjectPropertyData.oil_gas_points.features && type === 'oilgas') {
          mapState.oilGasJobMaps.addGeoJson(subjectPropertyData.oil_gas_points);
        }
        if (
          subjectPropertyData.adjoining_properties_boundaries &&
          subjectPropertyData.adjoining_properties_boundaries.features &&
          Object.keys(subjectPropertyData.adjoining_properties_boundaries.features).length > 1 &&
          type === 'adjoin'
        ) {
          mapState.adjoiningPropertiesBoundaries.addGeoJson(subjectPropertyData.adjoining_properties_boundaries);
          dispatch(
            updateJob({
              ...jobState,
              ...{
                adjoiningPropertiesBoundaries: setMapFindings(
                  subjectPropertyData.adjoining_properties_boundaries.features
                ),
              },
            })
          );
        }
        if (
          subjectPropertyData.water_wells &&
          typeof subjectPropertyData.water_wells.features !== 'undefined' &&
          subjectPropertyData.water_wells.features !== null &&
          Object.keys(subjectPropertyData.water_wells.features).length > 0 &&
          type === 'water'
        ) {
          mapState.waterWellsPointer.addGeoJson(subjectPropertyData.water_wells);
        }
        if (
          subjectPropertyData.soils &&
          typeof subjectPropertyData.soils.features !== 'undefined' &&
          subjectPropertyData.soils.features !== null &&
          Object.keys(subjectPropertyData.soils.features).length > 0 &&
          type === 'soils'
        ) {
          mapState.soilsPropertyLayer.addGeoJson(subjectPropertyData.soils);
        }

        if (
          subjectPropertyData.environmental_related_wells &&
          typeof subjectPropertyData.environmental_related_wells.features !== 'undefined' &&
          subjectPropertyData.environmental_related_wells.features !== null &&
          Object.keys(subjectPropertyData.environmental_related_wells.features).length > 0 &&
          type === 'monitor'
        ) {
          mapState.monitorWellsPointer.addGeoJson(subjectPropertyData.environmental_related_wells);
        }
        const subProperty = subjectPropertyData.subject_property;
        if (
          subProperty &&
          (type === 'astm' ||
            type === 'vec' ||
            type === 'topos' ||
            type === 'aerial' ||
            type === 'oilgas' ||
            type === 'adjoin' ||
            type === 'water' ||
            type === 'soils' ||
            type === 'monitor')
        ) {
          polygonObj = getType(subProperty.features[0].geometry);
          mapState.subjectPropertyShowLayer.addGeoJson(subProperty);
        }
        const centroidObj = centroid(polygonObj).geometry;
        if (centroidObj) {
          const latlng = new gMaps.LatLng(centroidObj.coordinates[1], centroidObj.coordinates[0]);
          gMap.panTo(latlng);
          if (
            type === 'vec' ||
            type === 'oilgas' ||
            type === 'adjoin' ||
            type === 'water' ||
            type === 'monitor' ||
            type === 'soils'
          ) {
            gMap.setZoom(16);
          } else {
            gMap.setZoom(15);
          }
        }
      } else {
        gMap.setZoom(5);
      }
    }
  };

  const setMapFindings = (entPoint: any) => {
    let jsonArray: any = '';
    if (Object.keys(entPoint).length > 0) {
      for (const val of entPoint) {
        if (jsonArray) {
          jsonArray = jsonArray.concat([val.properties]);
        } else {
          jsonArray = [val.properties];
        }
      }
    }
    return jsonArray;
  };

  const markerJson = (fetchData: any) => {
    const epnArray: any[] = [];
    for (const val of fetchData) {
      const epnRecord: any = {};
      epnRecord.location = Object.assign({});
      epnRecord.location.lon = val.geometry.coordinates[0];
      epnRecord.location.lat = val.geometry.coordinates[1];
      epnRecord.marker = 'STANDARD';
      epnRecord.name = val.properties.entity_name;
      epnRecord.type = 'ENTITY';
      epnRecord.details = val.properties;
      if (val.properties.entity_number) {
        epnRecord.entityNumber = val.properties.entity_number;
        epnArray.push(epnRecord);
      }
    }
    return epnArray;
  };
  const getFilterInfo = (fetchData: any) => {
    let jsonArray: any = '';
    for (const val of fetchData) {
      if (val.properties.vec_all === true) {
        if (jsonArray) {
          jsonArray = jsonArray.concat([val]);
        } else {
          jsonArray = [val];
        }
      }
    }
    return jsonArray;
  };

  useEffect(() => {
    const getEsaData = (jobId: string) => {
      try {
        jobState.activeJob.execute = false;
        dispatch(
          updateJob({
            ...jobState,
            ...{
              esaData: [],
              isLoadEsaData: false,
            },
          })
        );
        const requestBody: any = { jobId };
        if (requestBody) {
          axios
            .post(API_BASE_URL + '/api/getEsaData', requestBody, {
              headers: API_AUTH_HEADERS,
            })
            .then((response) => {
              if (response.data) {
                const esajson = response.data[0];
                if (Object.keys(esajson.esadata).length > 0) {
                  let jsonArray: any = '';
                  const entPoint = esajson.esadata.regulated_entities_point.features;
                  if (Object.keys(entPoint).length > 0) {
                    for (const val of entPoint) {
                      if (jsonArray) {
                        jsonArray = jsonArray.concat([val.properties]);
                      } else {
                        jsonArray = [val.properties];
                      }
                    }
                  }
                  dispatch(
                    updateJob({
                      ...jobState,
                      ...{
                        esaData: esajson.esadata,
                        mapFindings: jsonArray,
                        isLoadEsaData: true,
                        jobInit: true,
                      },
                    })
                  );
                }
              }
            })
            .catch((err) => {
              dispatch(
                updateJob({
                  ...jobState,
                  ...{
                    isLoadEsaData: true,
                    esaData: {},
                  },
                })
              );
              CeresLogger.error(err);
              return toast.error(`Data is processing please try later.`);
            });
        }
      } catch (e: any) {
        CeresLogger.error(e);
      }
    };
    if (jobState.activeJob.jobId && jobState.activeJob.execute) {
      getEsaData(jobState.activeJob.jobId);
    }
  }, [dispatch, jobState]);

  const downloadJobReport = (jobId: string) => {
    setDownload(true);
    try {
      const requestBody: any = { jobId };
      if (requestBody) {
        axios
          .post(
            API_BASE_URL + '/api/getJobsReport',
            //  'http://localhost:5000/ceres-platform-test/us-central1/api/getJobsReport',
            requestBody,
            {
              headers: API_AUTH_HEADERS,
            }
          )
          .then((response) => {
            if (response.data) {
              const linkSource = `data:application/pdf;base64,${response.data.file}`;
              const downloadLink = document.createElement('a');
              const fileName = response.data.name;
              downloadLink.href = linkSource;
              downloadLink.download = fileName;
              downloadLink.click();
            }
            setDownload(false);
          })
          .catch((err) => {
            CeresLogger.error(err);
            setDownload(false);
          });
      }
    } catch (e: any) {
      CeresLogger.error(e);
      setDownload(false);
    }
  };

  const getAirLists = () => {
    try {
      dispatch(
        updateAIEarth({
          ...aiEarthState,
          ...{ isLoading: true, activeTab: 'air' },
        })
      );
      axios
        .get(
          API_BASE_URL + '/api/eventEmissions/airLists',
          // 'http://localhost:5000/ceres-platform-test/us-central1/api/eventEmissions/corporates',
          {
            headers: API_AUTH_HEADERS,
          }
        )
        .then((response: any) => {
          const resultRecords = response.data[0].jsonlistb.air.map((rec: any) => {
            return rec.label;
          });
          dispatch(
            updateAIEarth({
              ...aiEarthState,
              ...{ isLoading: false, airListOptions: resultRecords },
            })
          );
        })
        .catch((err) => {
          dispatch(
            updateAIEarth({
              ...aiEarthState,
              ...{ isLoading: false },
            })
          );
          CeresLogger.error(err);
        })
        .finally();
    } catch (e: any) {
      CeresLogger.error(e);
    }
  };

  const onMarkerClick = (searchRecord: ISearchRecord | null) => {
    if (R.isNil(searchRecord)) {
      // return; // resetMapToInitial(); //test
    }
    props.setActiveMarkerRecord(searchRecord);
    setZoomLevel(18);
  };

  const doStorageDownload = R.contains(R.__, ['tceq_novell']);

  const getStorageRef = R.cond<string, StorageReference | null>([
    [R.equals('tceq_novell'), R.always(ref(getStorage(ceresApp), `gs://${process.env.REACT_APP_STORAGE_BUCKET_NOVELL}` || ''))],
    [R.T, R.always(null)],
  ]);

  let programSearchData: any = [];

  if (!!props.programQueryResult) {
    programSearchData = [...props.programQueryResult];
  }
  const columns = [
    {
      name: 'Entity Name',
      selector: 'entityName',
      sortable: true,
    },
    {
      name: 'Address',
      selector: 'address',
      sortable: true,
    },
    {
      name: 'Entity Source',
      selector: 'entitySource',
      cell: (row: any) => <SourceCell>{row.entitySource}</SourceCell>,
      sortable: true,
    },
  ];
  const onRowClick = (row: any) => {
    setAutoScroll(false);
    const indexKey = props.programQueryResult.findIndex(
      (indexData: any) => indexData.entityNumber === row.entityNumber
    );
    props.setKeyEvent(indexKey);
    if (row.location && row.location.lat) {
      gMap.panTo(getCenterWithOffset(row.location.lat, row.location.lon, -0.1, -0.15));
      setZoomLevel(18);
      gMap.setMapTypeId('satellite');
    }
    const panorama = gMap.getStreetView();
    panorama.setVisible(false);
    setStreetLocation(false);
    props.setActiveMarkerRecord(row);
    props.setShowProgramQueryDocPanel(true);
  };
  const streetViewUpdate = (streetLocation: any) => {
    if (streetLocation) {
      setTimeout(() => {
        streetLocation.setVisible(true);
      }, 200);
    }
  };
  const conditionalRowStyles = [
    {
      when: (row: any) => {
        if (row.entityNumber === (props.activeMarkerRecord !== null ? props.activeMarkerRecord.entityNumber : '')) {
          if (getAutoScroll) {
            const index = programSearchData.findIndex(
              (x: any) => x.entityNumber === props.activeMarkerRecord.entityNumber
            );
            const list: any = document.getElementById('row-' + index);
            if (list) {
              list.scrollIntoView();
            }
          }
          return true;
        } else {
          return false;
        }
      },
      style: {
        // tslint:disable-next-line: object-literal-key-quotes
        backgroundColor: CommonBgColor.backgroundColor,
        // tslint:disable-next-line: object-literal-key-quotes
        color: 'white !important',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  ];
  const [eidfocus, setEidfocus] = useState(false);

  const resetJobMap = () => {
    if (mapState.mapOverLay) {
      mapState.mapOverLay.setMap(null);
      mapState.mapOverLay = null;
    }
    mapState.layers = [];
    mapState.execute = false;
    mapState.jobLayers = [];
    if (typeof gMap !== 'undefined') {
      gMap.setZoom(5);
      gMap.setMapTypeId('roadmap');
    }
    dispatch(updateMap(mapState));

    if (mapState.subjectPropertyShowLayer) {
      mapState.subjectPropertyShowLayer.forEach((feature: any) => {
        mapState.subjectPropertyShowLayer.remove(feature);
      });
    }
    if (mapState.adjoiningPropertyLayer) {
      mapState.adjoiningPropertyLayer.forEach((feature: any) => {
        mapState.adjoiningPropertyLayer.remove(feature);
      });
    }
    if (mapState.waterWellsPointer) {
      mapState.waterWellsPointer.forEach((feature: any) => {
        mapState.waterWellsPointer.remove(feature);
      });
    }
    if (mapState.monitorWellsPointer) {
      mapState.monitorWellsPointer.forEach((feature: any) => {
        mapState.monitorWellsPointer.remove(feature);
      });
    }
    if (mapState.soilsPropertyLayer) {
      mapState.soilsPropertyLayer.forEach((feature: any) => {
        mapState.soilsPropertyLayer.remove(feature);
      });
    }
    dispatch(resetJob());
    dispatch(resetAir());
    dispatch(resetAIEarth());
    props.setShowProgramQueryDocPanel(false);
  };
  const focusEid = (key: any) => {
    const d = document;
    if (key === 'document') {
      setTimeout(() => {
        if (d != null) {
          // @ts-ignore
          d.getElementById('doc-query').focus();
        }
      }, 500);
      setEidfocus(false);
    } else if (key === 'program') {
      setTimeout(() => {
        if (d != null && !eidfocus) {
          if (d.activeElement) {
            // @ts-ignore
            d.getElementById('pro-query').focus();
            setEidfocus(true);
          }
        } else {
          setEidfocus(false);
        }
      }, 200);
    }
  };
  const record: ISearchRecord = props.activeMarkerRecord || dummyRecord;

  const favoritesFn = async ({type, bodyData}: {type: string, bodyData?: any}) => {
    bodyData = props.currentQueryText ? { ...bodyData, searchTerm: props.currentQueryText} : bodyData;
    setIsFavLoading(true);
    try {
      if(!type) {
        type = 'getAll';
      }
      const requestBody: any = {
        type,
      };
      if(type === 'remove' || type === 'create') {
        requestBody.record = bodyData;
      }
      if(type === 'create') {
        toast.success(`Item added to favorite list.`);
        const favList = [...myFavList];
        favList.push(bodyData);
        setMyFavList(favList);
        dispatch(updateAIEarth({
          ...aiEarthState,
          favorites: favList
        }));
      }
      if(type === 'remove') {
        const favList = [...myFavList];
        const index = favList.findIndex(x => x.contentId === bodyData.contentId);
        if(index >= 0) {
          favList.splice(index, 1);
        }
        setMyFavList(favList);
        dispatch(updateAIEarth({
          ...aiEarthState,
          favorites: favList,
          favoritesLoaded: true
        }));
        toast.success(`Item removed favorite list.`);
      }
      const result = await searchFavorite(requestBody);
      if(result.data) {
        if(type === 'getAll') {
          setMyFavList(result.data);
        }
      }
      setIsFavLoading(false);
    } catch (error: any) {
      setIsFavLoading(false);
      CeresLogger.error(error);
      if(type === 'getAll') {
        return toast.error(`Error while fetching favorites list.`);
      }
    }
  };

  useEffect(() => {
    favoritesFn({ type: 'getAll' });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    mapReset();
    props.setShowPanel(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getPageRoute]);

  // tslint:disable-next-line:no-shadowed-variable
  const getBoundingBox = (uniqueEntityRecords: ISearchRecord[]): any => {
    const locations: { lat: number; lon: number }[] = R.map(
      // tslint:disable-next-line:no-shadowed-variable
      (record: ISearchRecord) => record.location,
      uniqueEntityRecords
    );

    if (R.isEmpty(locations)) {
      return {
        nw: {
          lat: 29.0,
          lng: -95.0,
        },
        se: {
          lat: 29.5,
          lng: -95.5,
        },
      };
    }

    const [latitudes, longitudes] = R.compose<
      { lat: number; lon: number }[],
      [string, number][][],
      [string, number][],
      {
        [key: string]: [string, number][];
      },
      [number[], number[]]
    >(
      (groupedData: { [key: string]: [string, number][] }): [number[], number[]] => {
        const groupedLats = groupedData.lat || [];
        const groupedLongs = groupedData.lon || [];
        const getLocValue = R.map((item: [string, number]) => R.last(item) as number);
        return [getLocValue(groupedLats), getLocValue(groupedLongs)];
      },
      R.groupBy(([locKey, _]) => locKey),
      R.reduce((acc, item) => [...acc, ...item], [] as [string, number][]),
      R.map(R.toPairs)
    )(locations);

    // const latitudes = R.flatten(R.map(R.toPairs, locations));

    return {
      nw: {
        lat: Math.max(...latitudes),
        lng: Math.min(...longitudes),
      },
      se: {
        lat: Math.min(...latitudes),
        lng: Math.max(...longitudes),
      },
    };
  };

  const mapReset = () => {
    const { zoom } = googleMapReactUtil.fitBounds(getBoundingBox(uniqueEntityRecords), {
      width: 1172,
      height: 235,
    });
    // setActiveMarkerRecord(null);
    setZoomLevel(zoom);
  };

  const checkIsFav = (contentId: string) => {
    const index = myFavList.findIndex(x => x.contentId === contentId);
    return index >= 0;
  };

  const onNavigationItemClickHandle = (searchRecord: ISearchRecord, e: any) => {
    props.setActiveMarkerRecord(searchRecord);
    props.setShowDocPanel(false);
    props.setShowProgramQueryPanel(false);
    props.setShowPanel(true);
    if (!isValidLatLong(searchRecord.location.lat, searchRecord.location.lon)) {
      toast.info(
        <span>
          AI Earth is not mapping this site because the original agency source data does not provide
          sufficient information for an accurate geocode.
        </span>,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 10000,
        }
      );
      return;
    }
    gMap.panTo(getCenterWithOffset(searchRecord.location.lat || 0, searchRecord.location.lon || 0, -0.1, -0.15));
    gMap.setMapTypeId('satellite');
    setZoomLevel(18);
  };

  const RenderFavTitle = () => {
    return isFavLoading ? <span>Favorites <Spinner size="sm" animation="border" variant="primary" /></span> : <span>Favorites</span>;
  };

  const plotChemLatLong = (ele: any) => {
    const epnArray: any[] = [];
    if (ele.location.geomz) {
      const decryptedData = CryptoJS.AES.decrypt(ele.location.geomz, secKey, {
        iv,
        mode: CryptoJS.mode.CBC,
      }).toString(CryptoJS.enc.Utf8);
      const latLon = decryptedData.split(',');
      ele.location.lon = parseFloat(latLon[0]);
      ele.location.lat = parseFloat(latLon[1]);
    }
    if (ele.location.geom_json) {
      for (const epnKey in ele.location.geom_json) {
        if (ele.location.geom_json.hasOwnProperty(epnKey)) {
          for (const epnInnerKey in ele.location.geom_json[epnKey]) {
            if (ele.location.geom_json[epnKey].hasOwnProperty(epnInnerKey)) {
              const element = ele.location.geom_json[epnKey][epnInnerKey];
              const epnRecord: any = {};
              if (element.geom) {
                const decryptedData = CryptoJS.AES.decrypt(element.geom, secKey, {
                  iv,
                  mode: CryptoJS.mode.CBC,
                }).toString(CryptoJS.enc.Utf8);
                const latLon = decryptedData.split(',');
                epnRecord.location = Object.assign({});
                epnRecord.location.lon = parseFloat(latLon[0]);
                epnRecord.location.lat = parseFloat(latLon[1]);
                epnRecord.marker = element.marker;
                epnRecord.name = element.name;
                epnRecord.type = element.type;
                epnRecord.details = element.details;
                if (ele.entityNumber) {
                  epnRecord.entityNumber = ele.entityNumber;
                  epnArray.push(epnRecord);
                }
              }
            }
          }
        }
      }
    }
    setChemPlantsEpnResult(epnArray);
    return ele;
  };

  const getChemPlantLocation = async (chemPlantItem: any, e: any, navigate: boolean = true) => {
    const response = await axios.post(`${process.env.REACT_APP_CERES_URL}/getEntityLocation`, {
      data: {
        query: {
          entityNumber: chemPlantItem.entity_number
        }
      }
    });
    let searchRecordTemplate = dummyRecord;

    searchRecordTemplate = {
      ...searchRecordTemplate,
      entityName: chemPlantItem.entity_name,
      entityNumber: chemPlantItem.entity_number,
      location: response.data.result.location,
      contentId: chemPlantItem.content_id
    };

    searchRecordTemplate = plotChemLatLong(searchRecordTemplate);
    if(navigate) {
      onNavigationItemClickHandle(searchRecordTemplate, e);
      return;
    } else {
      return searchRecordTemplate;
    }
  };

  const onFavoriteClick = async (chemRecord: any, isFav: any, e: any) => {
    chemRecord = await getChemPlantLocation(chemRecord, e, false);
    setSelectedChemPlant(chemRecord);
    if(isFav) {
      favoritesFn({
        type: 'remove',
        bodyData: {
          contentId: chemRecord.contentId,
        },
      });
    } else {
      favoritesFn({
        type: 'create',
        bodyData: chemRecord,
      });
    }
  };

  const getSpinner = (hideSpinner = false) => {
    if(hideSpinner) {
      return;
    }

    return (<SpinnerContainer><Spinner
            as="span"
            variant="light"
            animation="border"
            role="status"
            aria-hidden="true"
          /></SpinnerContainer>);
  };

  return (
    <Row noGutters>
      {/* {!aiEarthState.isCollapse && ( */}
      <Col lg={2} sm={12} style={{ display: `${aiEarthState.isCollapse ? 'none' : 'block'}` }}>
        {/* <button onClick={() => mapReset()}>Reset map</button> */}
        <StyledTabs>
          {props.getPageRoute === '/ai-earth' && (
            <Tabs
              defaultActiveKey="document"
              id="noanim-tab-example"
              onSelect={(key: any) => {
                resetJobMap();
                mapReset();
                if(key === 'favorites') {
                  if(props.plotLatLong) {
                    props.plotLatLong(myFavList);
                  }
                }
                props.setShowPanel(false);
                focusEid(key);
                if (key === 'air' && !aiEarthState.airListOptions) {
                  getAirLists();
                } else {
                  dispatch(
                    updateAIEarth({
                      ...aiEarthState,
                      ...{
                        activeTab: key,
                        mainDrawer: '',
                        subDrawer: '',
                        data: null,
                      },
                    })
                  );
                }
                dispatch(resetJob());
                props.setShowProgramQueryDocPanel(false);
                props.setShowProgramQueryPanel(false);
                dispatch(
                  updateAir({
                    ...airState,
                    ...{ activeRecord: dummyRecord },
                  })
                );
              }}
            >
              <Tab eventKey="document" title="Documents">
                <RecordNavigation
                  // mapNavigationElementId={MAP_NAVIGATION_ELEMENT_ID}
                  records={uniqueEntityRecords}
                  record={record}
                  onNavigationItemClick={onNavigationItemClickHandle}
                  SearchForm={SearchForm}
                  SearchPagination={SearchPagination}
                  onPreviousClick={props.onPreviousClick}
                  onNextClick={props.onNextClick}
                  result={props.result}
                  favoritesFn={favoritesFn}
                  checkIsFav={checkIsFav}
                  showRecordsLoader={props.showRecordsLoader}
                />
              </Tab>
              <Tab eventKey="program" title="Databases">
                <Fragment>
                  <Scrollbars
                    style={{
                      height: 'calc(100vh - 250px)',
                      background: 'rgba(108, 117, 125, 0.5)'
                    }}
                  >
                    <ProgramQuery />
                  </Scrollbars>
                </Fragment>
              </Tab>
              <Tab eventKey="jobs" title="Job-Tasks(Beta)">
                <Fragment>
                  <Jobs
                    gMap={gMap}
                    gMaps={gMaps}
                    context={context}
                    setJobReport={setJobReport}
                    setSubjectPropertyEsaData={setSubjectPropertyEsaData}
                    resetJobMap={resetJobMap}
                  />
                </Fragment>
              </Tab>
              <Tab disabled={isFavLoading} eventKey="favorites" title={<RenderFavTitle />}>
                <RecordNavigationContainer>
                <Scrollbars
                    style={{
                      height: 'calc(100vh - 243px)',
                      background: 'rgb(181,186,190)',
                    }}
                  >
                  {R.map((myFavItem: ISearchRecord) => {
                    return (
                      <OverlayTrigger
                        key={myFavItem.contentId}
                        trigger="hover"
                        placement="bottom"
                        overlay={
                          <ReactTooltip effect="solid" html={true} />
                        }
                      >
                        <FavIconBtn>
                        <Button
                            data-tip={
                              ReactDOMServer.renderToString(
                                <div>
                                 <strong>{myFavItem.entityNumber}</strong>
                            <br />
                            {myFavItem.cityName}
                                </div>
                              )
                            }
                          style={{
                            backgroundColor: record.entityNumber === myFavItem.entityNumber ? '#005FBC' : '#005FBC', border:'none'
                          }}
                          onClick={(e: any) => {
                            onNavigationItemClickHandle(myFavItem, e);
                          }}
                          size="sm"
                          block={true}
                        >
                          {myFavItem.entityName || myFavItem.entityNumber}
                        </Button>
                        <OverlayTrigger
                            overlay={<ReactTooltip place="bottom" effect="solid" />}
                          >
                           <IconButton onClick={() => {
                            favoritesFn({type: 'remove', bodyData: {
                              contentId: myFavItem.contentId
                          }});
                          }} aria-label="fav-icon" component="span">
                          <StarIcon style={{color: '#faaf00'}} data-tip="Remove from Favorites" />
                          </IconButton>
                        </OverlayTrigger>
                        </FavIconBtn>
                      </OverlayTrigger>
                    );
                  }, myFavList)}
                  </Scrollbars>
                </RecordNavigationContainer>
              </Tab>
              <Tab eventKey="ChemicalPlants" title="Chem Plants">
                <RecordNavigationContainer>
                <SearchForm isChemPlant={true} />
                  {getSpinner(!props.isChemPlantLoading)}
                  {<Scrollbars
                    style={{
                      height: 'calc(100vh - 210px)',
                      background: 'rgb(181,186,190)',
                      marginTop: '10px'
                    }}
                  >
                  {props.chemPlantResults.map((chemPlantItem: any) => {
                    return (
                      <OverlayTrigger
                        trigger="hover"
                        placement="bottom"
                        overlay={
                          <ReactTooltip effect="solid" html={true} />
                        }
                      >
                        <FavIconBtn>
                        <Button
                          data-tip={
                            ReactDOMServer.renderToString(
                              <div>
                               <strong>{chemPlantItem.entity_number}</strong>
                            <br />
                              </div>
                            )
                          }
                          style={{
                            backgroundColor: record.entityNumber === chemPlantItem.entity_number ? '#005FBC' : '#005FBC', border:'none'
                          }}
                          onClick={(e: any) => {
                            props.setShowPanel(true);
                            getChemPlantLocation(chemPlantItem, e);
                          }}
                          size="sm"
                          block={true}
                        >
                          {chemPlantItem.entity_name}
                        </Button>
                        {checkIsFav(chemPlantItem.content_id) ?
                        <OverlayTrigger
                           overlay={<ReactTooltip place="right" effect="solid" />}
                            >
                            <IconButton onClick={(e: any) => {
                              onFavoriteClick(
                              chemPlantItem,
                              true,
                              e
                            );
                            }} aria-label="fav-icon" component="span" data-tip="Remove from Favorites">
                            <StarIcon style={{color: '#faaf00'}}  />
                          </IconButton>
                        </OverlayTrigger>
                          :
                          <OverlayTrigger
                          overlay={<ReactTooltip place="right" effect="solid" />}
                           >
                          <IconButton onClick={(e: any) => {
                              onFavoriteClick(
                              chemPlantItem,
                              false,
                              e
                            );
                          }
                          } aria-label="fav-icon" component="span" data-tip="Add to Favorites">
                            <StarBorderIcon style={{color: '#faaf00'}} />
                          </IconButton>
                       </OverlayTrigger>
                        }
                        </FavIconBtn>
                      </OverlayTrigger>
                    );
                  })}
                  </Scrollbars>}
                </RecordNavigationContainer>
              </Tab>
            </Tabs>
          )}
          {props.getPageRoute === '/ai-earth-air' && (
            <AIEarthAirTab
              getAirLists={getAirLists}
              setBoundingBox={props.setBoundingBox}
              boundingBox={props.boundingBox}
              coords={props.coords}
              ProgramQuery={ProgramQuery}
              setActiveTabDrawer={setActiveTabDrawer}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          )}
        </StyledTabs>
        <CollapsBar
          onClick={() => {
            dispatch(
              updateAIEarth({
                ...aiEarthState,
                ...{ isCollapse: true },
              })
            );
          }}
        >
          <ChevronsLeft /> Collapse
        </CollapsBar>
      </Col>
      {/* )} */}
      {aiEarthState.activeTab === 'jobs' && jobState.jobInit && (
        <Col lg={jobState.jobInit ? 3 : 0}>
          <ButtonContainer>
            <Button
              style={{ padding: '5px' }}
              onClick={() => {
                resetJobMap();
                dispatch(
                  updateJob({
                    ...jobState,
                    ...{ jobInit: false },
                  })
                );
              }}
              size="sm"
            >
              <ChevronLeft />
            </Button>
          </ButtonContainer>
          <JobTasksContainer>
            {jobState.panelType === 'tasks' && (
              <TasksContainer>
                <h4>EnviroAI AutoPhase 1 - {jobState.activeJob.name}</h4>
                {!jobState.activeJob.jobId && (
                  <List>
                    <span
                      onClick={() => {
                        dispatch(
                          updateJob({
                            ...jobState,
                            ...{
                              wizardOpen: true,
                              activeStep: 3,
                            },
                          })
                        );
                      }}
                    >
                      Edit Subject Property Data
                    </span>
                  </List>
                )}
                <List>
                  <span
                    onClick={() => {
                      dispatch(
                        updateJob({
                          ...jobState,
                          ...{
                            panelType: 'editAddressPanel',
                          },
                        })
                      );
                    }}
                  >
                    Job Info
                  </span>
                </List>
                <List>
                  <span
                    onClick={() => {
                      dispatch(
                        updateJob({
                          ...jobState,
                          ...{
                            jobInit: false,
                            mapFindingTab: true,
                          },
                        })
                      );
                    }}
                  >
                    Map Findings
                  </span>
                </List>
                <List>
                  <span
                    onClick={() => {
                      dispatch(
                        updateJob({
                          ...jobState,
                          ...{
                            panelType: 'jobsIdentifyManagers',
                          },
                        })
                      );
                    }}
                  >
                    Identify Key Site Managers
                  </span>
                </List>
                <List>
                  <span
                    onClick={() => {
                      dispatch(
                        updateJob({
                          ...jobState,
                          ...{
                            panelType: 'jobsIdentifyUsers',
                          },
                        })
                      );
                    }}
                  >
                    Identify Users
                  </span>
                </List>
                <List
                  onClick={() => {
                    dispatch(
                      updateJob({
                        ...jobState,
                        ...{
                          panelType: 'adjoiningProperty',
                        },
                      })
                    );
                  }}
                >
                  <span>Identify Adjoining Properties</span>
                </List>
                <List
                  onClick={() => {
                    dispatch(
                      updateJob({
                        ...jobState,
                        ...{
                          panelType: 'jobsHistoricalSummary',
                        },
                      })
                    );
                  }}
                >
                  <span>Historical Use Summary of Subject and Adjoining Properties</span>
                </List>
                <List
                  onClick={() => {
                    dispatch(
                      updateJob({
                        ...jobState,
                        ...{
                          panelType: 'jobsHistoricalBordering',
                        },
                      })
                    );
                  }}
                >
                  <span>Historical Use of Bordering ROWs</span>
                </List>
                <List
                  onClick={() => {
                    dispatch(
                      updateJob({
                        ...jobState,
                        ...{
                          panelType: 'jobsIdentifyInterviews',
                        },
                      })
                    );
                  }}
                >
                  <span>Identify Other Interviewees</span>
                </List>
                <List
                  onClick={() => {
                    dispatch(
                      updateJob({
                        ...jobState,
                        ...{
                          panelType: 'jobsUserQuestionaire',
                        },
                      })
                    );
                  }}
                >
                  <span>User Questionnaire</span>
                </List>
                <List
                  onClick={() => {
                    dispatch(
                      updateJob({
                        ...jobState,
                        ...{
                          panelType: 'jobsKSMQuestionaire',
                        },
                      })
                    );
                  }}
                >
                  <span>KSM Questionnaire</span>
                </List>
                <List
                  onClick={() => {
                    dispatch(
                      updateJob({
                        ...jobState,
                        ...{
                          panelType: 'jobsPublicInformationRequests',
                        },
                      })
                    );
                  }}
                >
                  <span>Public Information Requests</span>
                </List>
                <List
                  onClick={() => {
                    dispatch(
                      updateJob({
                        ...jobState,
                        ...{
                          panelType: 'jobsVECWorksheet',
                        },
                      })
                    );
                  }}
                >
                  <span>VEC Worksheet</span>
                </List>
                <List
                  onClick={() => {
                    dispatch(
                      updateJob({
                        ...jobState,
                        ...{
                          panelType: 'jobsGradientWorksheet',
                        },
                      })
                    );
                  }}
                >
                  <span>GW Gradient Worksheet</span>
                </List>
                <List
                  onClick={() => {
                    dispatch(
                      updateJob({
                        ...jobState,
                        ...{
                          panelType: 'jobsStatus',
                        },
                      })
                    );
                  }}
                >
                  <span>Job Status</span>
                </List>
                <List
                  onClick={() => {
                    dispatch(
                      updateJob({
                        ...jobState,
                        ...{
                          panelType: 'hazardMaps',
                        },
                      })
                    );
                  }}
                >
                  <span>Hazard Maps</span>
                </List>
                {getJobReport === true && jobState.activeJob.hasdatabasereport && (
                  <List
                    onClick={() => {
                      dispatch(
                        updateJob({
                          ...jobState,
                          ...{
                            panelType: 'jobReports',
                          },
                        })
                      );
                    }}
                  >
                    <span>Job Reports</span>
                  </List>
                )}
              </TasksContainer>
            )}
            {jobState.panelType === 'adjoiningProperty' && (
              <>
                <PanelNavigation>
                  <Button
                    onClick={() => {
                      dispatch(
                        updateJob({
                          ...jobState,
                          panelType: 'tasks',
                        })
                      );
                    }}
                    size="sm"
                  >
                    <ChevronLeft /> Back
                  </Button>
                  <h5>
                    <b>{jobState.panelType === 'adjoiningProperty' ? 'Adjoining Properties' : ''}</b>
                  </h5>
                </PanelNavigation>
                <PanelContent>
                  <JobsAdjoiningProperties />
                </PanelContent>
              </>
            )}
            {jobState.panelType === 'editAddressPanel' && (
              <>
                <PanelNavigation>
                  <Button
                    onClick={() => {
                      dispatch(
                        updateJob({
                          ...jobState,
                          panelType: 'tasks',
                        })
                      );
                    }}
                    size="sm"
                  >
                    <ChevronLeft /> Back
                  </Button>
                  <h5>
                    <b>{jobState.panelType === 'editAddressPanel' ? 'Edit Job' : ''}</b>
                  </h5>
                </PanelNavigation>
                <PanelContent>
                  <JobsEditAddress />
                </PanelContent>
              </>
            )}
            {jobState.panelType === 'jobsIdentifyUsers' && (
              <>
                <PanelNavigation>
                  <Button
                    onClick={() => {
                      dispatch(
                        updateJob({
                          ...jobState,
                          panelType: 'tasks',
                        })
                      );
                    }}
                    size="sm"
                  >
                    <ChevronLeft /> Back
                  </Button>
                  <h5>
                    <b>{jobState.panelType === 'jobsIdentifyUsers' ? 'Identify Users' : ''}</b>
                  </h5>
                </PanelNavigation>
                <PanelContent>
                  <JobsIdentifyUsers />
                </PanelContent>
              </>
            )}
            {jobState.panelType === 'jobsIdentifyManagers' && (
              <>
                <PanelNavigation>
                  <Button
                    onClick={() => {
                      dispatch(
                        updateJob({
                          ...jobState,
                          panelType: 'tasks',
                        })
                      );
                    }}
                    size="sm"
                  >
                    <ChevronLeft /> Back
                  </Button>
                  <h5>
                    <b>{jobState.panelType === 'jobsIdentifyManagers' ? 'Identify Key Site Managers' : ''}</b>
                  </h5>
                </PanelNavigation>
                <PanelContent>
                  <JobsIdentifyManagers />
                </PanelContent>
              </>
            )}
            {[
              'jobsHistoricalSummary',
              'jobsHistoricalBordering',
              'jobsIdentifyInterviews',
              'jobsUserQuestionaire',
              'jobsKSMQuestionaire',
              'jobsPublicInformationRequests',
              'jobsVECWorksheet',
              'jobsGradientWorksheet',
              'jobsStatus',
            ].includes(jobState.panelType) && (
              <>
                <PanelNavigation>
                  <Button
                    onClick={() => {
                      dispatch(
                        updateJob({
                          ...jobState,
                          panelType: 'tasks',
                        })
                      );
                    }}
                    size="sm"
                  >
                    <ChevronLeft /> Back
                  </Button>
                  <h5>
                    <b>
                      {jobState.panelType === 'jobsHistoricalSummary'
                        ? 'Historical Use Summary of Subject and Adjoining Properties'
                        : ''}
                      {jobState.panelType === 'jobsHistoricalBordering' ? 'Historical Use of Bordering Rows' : ''}
                      {jobState.panelType === 'jobsIdentifyInterviews' ? 'Identify Other Interviewees' : ''}
                      {jobState.panelType === 'jobsUserQuestionaire' ? 'User Questionnaire' : ''}
                      {jobState.panelType === 'jobsKSMQuestionaire' ? 'KSM Questionnaire' : ''}
                      {jobState.panelType === 'jobsPublicInformationRequests' ? 'Public Information Requests' : ''}
                      {jobState.panelType === 'jobsVECWorksheet' ? 'VEC Worksheet' : ''}
                      {jobState.panelType === 'jobsGradientWorksheet' ? 'GW Gradient Worksheet' : ''}
                      {jobState.panelType === 'jobsStatus' ? 'Job Status' : ''}
                    </b>
                  </h5>
                </PanelNavigation>
                <PanelContent>
                  <p>In Development</p>
                </PanelContent>
              </>
            )}

            {jobState.panelType === 'hazardMaps' && (
              <>
                <PanelNavigation>
                  <Button
                    onClick={() => {
                      dispatch(
                        updateJob({
                          ...jobState,
                          panelType: 'tasks',
                        })
                      );
                    }}
                    size="sm"
                  >
                    <ChevronLeft /> Back
                  </Button>
                  <h5>
                    <b>{jobState.panelType === 'hazardMaps' ? 'Hazard Maps' : ''}</b>
                  </h5>
                </PanelNavigation>
                <PanelContent style={{ textAlign: 'center' }}>
                  {jobState.isLoadEsaData === true && (
                    <HazardMaps
                      onClick={() => {
                        setSubjectPropertyEsaData(jobState.esaData, 'all');
                      }}
                    >
                      <RoomIcon /> Hazard Maps
                    </HazardMaps>
                  )}
                  {jobState.isLoadEsaData === false && (
                    <ReportDownload disabled>
                      <Spinner
                        as="span"
                        variant="light"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </ReportDownload>
                  )}
                </PanelContent>
              </>
            )}

            {jobState.panelType === 'jobReports' && getJobReport === true && jobState.activeJob.hasdatabasereport && (
              <>
                <PanelNavigation>
                  <Button
                    onClick={() => {
                      dispatch(
                        updateJob({
                          ...jobState,
                          panelType: 'tasks',
                        })
                      );
                    }}
                    size="sm"
                  >
                    <ChevronLeft /> Back
                  </Button>
                  <h5>
                    <b>{jobState.panelType === 'jobReports' ? 'Job Reports' : ''}</b>
                  </h5>
                </PanelNavigation>
                <PanelContent style={{ textAlign: 'center' }}>
                  {isDownload === false && (
                    <ReportDownload
                      onClick={() => {
                        downloadJobReport(jobState.activeJob.jobId);
                      }}
                    >
                      <Download /> Download Job Reports
                    </ReportDownload>
                  )}
                  {isDownload === true && (
                    <ReportDownload disabled>
                      <Spinner
                        as="span"
                        variant="light"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </ReportDownload>
                  )}
                </PanelContent>
              </>
            )}
          </JobTasksContainer>
        </Col>
      )}
      {jobState.mapFindingTab && (
        <MapsFinding
          doStorageDownload={doStorageDownload}
          getStorageRef={getStorageRef}
          context={context}
          setDownloadTriggers={setDownloadTriggers}
          downloadTriggers={downloadTriggers}
          gMap={gMap}
          gMaps={gMaps}
        />
      )}
      {aiEarthState.mainDrawer === 'air' && (
        <Col lg={3}>
          <ButtonContainer>
            <Button
              style={{ padding: '5px' }}
              onClick={() => {
                dispatch(
                  updateAIEarth({
                    ...aiEarthState,
                    ...{ mainDrawer: '' },
                  })
                );
              }}
              size="sm"
            >
              <ChevronLeft />
            </Button>
          </ButtonContainer>
          <RNDataContainer>
            <AirTabDrawer
              record={airState.activeRecord}
              doStorageDownload={doStorageDownload}
              getStorageRef={getStorageRef}
              context={context}
              downloadTriggers={downloadTriggers}
              setDownloadTriggers={setDownloadTriggers}
              showProgramQueryPanel={true}
              activeTabDrawer={activeTabDrawer}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </RNDataContainer>
        </Col>
      )}
      {aiEarthState.mainDrawer === '' && props.showPanel && (
        <Col lg={3} sm={props.showPanel ? 12 : 0}>
          <ButtonContainer>
            <Button
              style={{ padding: '5px' }}
              onClick={() => {
                props.setShowPanel(false);
                props.setShowDocPanel(false);
              }}
              size="sm"
            >
              <ChevronLeft />
            </Button>
          </ButtonContainer>
          <RNDataContainer>
            {(
              <RecordMarker
                setRelatedDocument={setRelatedDocument}
                currentQueryText={props.currentQueryText}
                onInfoWindowClose={() => onMarkerClick(null)}
                key={record.contentId}
                onMarkerClick={() => onMarkerClick(record)}
                lat={record.location.lat}
                lng={record.location.lon}
                isActive={true}
                record={record.contentId ? record : selectedChemPlant}
                isMap={false}
                showDocPanel={props.showDocPanel}
                showPanel={props.showPanel}
                setShowDocPanel={props.setShowDocPanel}
              />
            )}
          </RNDataContainer>
        </Col>
      )}
      {aiEarthState.mainDrawer === '' && props.showDocPanel && !!relatedDocument && (
        <Col lg={props.showDocPanel ? 3 : 0} sm={12}>
          <PreviewButtonContainer>
            <Button
              style={{ padding: '5px' }}
              onClick={() => {
                props.setShowDocPanel(false);
              }}
              size="sm"
            >
              <ChevronLeft />
            </Button>
          </PreviewButtonContainer>
          <DocPreviewContainer>
            <p>
              Type: <b>{relatedDocument.title}</b>
            </p>
            <p>
              Release Date: <b>{relatedDocument.releaseDate}</b>
            </p>
            <p>
              Category: <b>{relatedDocument.seriesName}</b>
            </p>
            <p>
              <Button
                onClick={() => {
                  if (!context.isAuthenticated) {
                    return toast.warn(
                      <span>
                        Please <Alert.Link href="/login">login</Alert.Link> to download files.
                      </span>,
                      {
                        position: toast.POSITION.BOTTOM_CENTER,
                      }
                    );
                  }
                  if (context.isTier(TIERS.FREE) && !context.isLubrizol()) {
                    return toast.warn(<span>Please upgrade to BASIC or PLATINUM to download files.</span>, {
                      position: toast.POSITION.BOTTOM_CENTER,
                    });
                  }
                  if (doStorageDownload(relatedDocument.entitySource)) {
                    const storageRef = getStorageRef(relatedDocument.entitySource);
                    if (R.isNil(storageRef)) {
                      return toast.error(`Oops, could not find the file.`);
                    }
                    setDownloadTriggers(R.append(relatedDocument.contentId, downloadTriggers));
                    return storageRef
                      .ref(relatedDocument.fileName)
                      .getDownloadURL()
                      .then((url) => window.open(url, '_blank', 'noopener,noreferrer'))
                      .catch((err) => toast.error(err.message))
                      .finally(() => setDownloadTriggers(R.without([relatedDocument.contentId], downloadTriggers)));
                  }
                  if (!relatedDocument.fileUrl) {
                    return toast.warn(
                      <span>
                        No associated file was found for the content id <b>{relatedDocument.contentId}</b>
                      </span>
                    );
                  }
                  window.open(relatedDocument.fileUrl, '_blank');
                }}
              >
                Download
              </Button>
            </p>
            <ContentTD
              dangerouslySetInnerHTML={{
                __html:
                  relatedDocument.hitContent.length > 0
                    ? relatedDocument.hitContent
                        // Remove non-ascii chars.
                        .map((str: string) =>
                          /*eslint no-control-regex: 0*/
                          str.replace(/[^\x00-\x7F]/g, '')
                        )
                        .join('<br />')
                    : '<h3>No Preview Available</h3>',
              }}
            />
          </DocPreviewContainer>
        </Col>
      )}
      {aiEarthState.mainDrawer === '' && props.showProgramQueryPanel && (
        <Col lg={props.showProgramQueryPanel ? 3 : 0}>
          <ButtonContainer>
            <CustomButton
              onClick={() => {
                props.setShowProgramQueryPanel(false);
              }}
              size="sm"
            >
              {props.isLoading ? <Spinner animation="border" variant="light" size="sm" /> : <ChevronLeft />}
            </CustomButton>
            {props.programQueryResultCount ? (
              <SearchPagination
                isDisabled={false}
                isFirstPage={(!!programSearchData && databaseState.offset === 0) || false}
                isLastPage={(programSearchData && databaseState.offset + 50 >= props.programQueryResultCount) || false}
                totalRecords={props.programQueryResultCount}
                onPreviousClick={() => {
                  props.programQueryPreviousClick();
                }}
                onNextClick={() => {
                  props.programQueryNextClick();
                }}
              />
            ) : null}
          </ButtonContainer>
          <GridContainer>
            <StyledDataTable
              columns={columns}
              data={programSearchData}
              noHeader={true}
              fixedHeaderScrollHeight={'calc(100vh - 225px)'}
              fixedHeader={true}
              pointerOnHover
              striped
              onSort={props.handleSort}
              sortServer={true}
              progressPending={props.isLoading}
              onRowClicked={onRowClick || undefined}
              highlightOnHover
              conditionalRowStyles={conditionalRowStyles}
            />
          </GridContainer>
        </Col>
      )}
      {aiEarthState.mainDrawer === '' && props.showProgramQueryDocPanel && (
        <Col lg={props.showProgramQueryDocPanel ? 3 : 0}>
          <PreviewButtonContainer>
            <Button
              style={{ padding: '5px' }}
              onClick={() => {
                props.setShowProgramQueryDocPanel(false);
                streetViewUpdate(getStreetLocation);
              }}
              size="sm"
            >
              <ChevronLeft />
            </Button>
          </PreviewButtonContainer>
          <RNDataContainer>
            <ProgramQueryDocDrawer
              record={record}
              doStorageDownload={doStorageDownload}
              getStorageRef={getStorageRef}
              context={context}
              downloadTriggers={downloadTriggers}
              setDownloadTriggers={setDownloadTriggers}
              showProgramQueryPanel={props.showProgramQueryPanel}
            />
          </RNDataContainer>
        </Col>
      )}
      {(!props.showProgramQueryPanel || jobState.jobInit || aiEarthState.mainDrawer !== '') && (
        <Col
          lg={
            (props.showPanel ||
            jobState.jobInit ||
            jobState.jobFeatureTab ||
            jobState.mapFindingTab ||
            aiEarthState.mainDrawer !== ''
              ? props.showDocPanel || (jobState.jobFeatureTab && jobState.mapFindingTab)
                ? 4
                : 7
              : 10) + (aiEarthState.isCollapse ? 2 : 0)
          }
          sm={12}
        >
          <div>
            {(context.isTier(TIERS.SUPER_ADMIN) || ((context.isTier(TIERS.PLATINUM) || context.isTier(TIERS.DAYPASS)) && context.isAuthenticated) || (context.isLubrizol() && context.isAuthenticated)) && (
              <AiEarthMap
                records={aiEarthState.data ? aiEarthState.data.records : records}
                programQueryEPNResult={aiEarthState.data ? aiEarthState.data.epnData : [...props.programQueryEPNResult, ...chemPlantsEpnResult]}
                showNavigation={showNavigation}
                setShowNavigation={setShowNavigation}
                showProgramQueryPanel={props.showProgramQueryPanel}
                boundingBox={props.boundingBox}
                showProgramQueryDocPanel={props.showProgramQueryDocPanel}
                setShowProgramQueryDocPanel={props.setShowProgramQueryDocPanel}
                activeMarkerRecord={airState.activeRecord !== dummyRecord ? airState.activeRecord : props.activeMarkerRecord}
                setActiveMarkerRecord={props.setActiveMarkerRecord}
                setActivePanel={props.setShowPanel}
                gMap={gMap}
                setGMap={setGMap}
                gMaps={gMaps}
                setGMaps={setGMaps}
                rectangle={rectangle}
                setRectangle={setRectangle}
                onRectDrag={props.onRectDrag}
                getCenterWithOffset={getCenterWithOffset}
                zoomLevel={zoomLevel}
                setZoomLevel={setZoomLevel}
                setShowDocPanel={props.setShowDocPanel}
                setStreetLocation={setStreetLocation}
                setAutoScroll={setAutoScroll}
                setSubjectPropertyEsaData={setSubjectPropertyEsaData}
                resetJobMap={resetJobMap}
                context={context}
              />
            )}
          </div>
        </Col>
      )}
      {props.showProgramQueryPanel && (
        <Col lg={props.showProgramQueryPanel ? (props.showProgramQueryDocPanel ? 4 : 7) : 0} sm={12}>
          <div>
            {(context.isTier(TIERS.SUPER_ADMIN) || ((context.isTier(TIERS.PLATINUM) || context.isTier(TIERS.DAYPASS)) && context.isAuthenticated) || (context.isLubrizol() && context.isAuthenticated)) && (
              <AiEarthMap
                records={props.programQueryMapResult ? props.programQueryMapResult : []}
                programQueryEPNResult={[...props.programQueryEPNResult, ...chemPlantsEpnResult]}
                showNavigation={showNavigation}
                showProgramQueryPanel={props.showProgramQueryPanel}
                boundingBox={props.boundingBox}
                showProgramQueryDocPanel={props.showProgramQueryDocPanel}
                setShowProgramQueryDocPanel={props.setShowProgramQueryDocPanel}
                setShowNavigation={setShowNavigation}
                activeMarkerRecord={props.activeMarkerRecord}
                setActiveMarkerRecord={props.setActiveMarkerRecord}
                setActivePanel={props.setShowPanel}
                gMap={gMap}
                setGMap={setGMap}
                gMaps={gMaps}
                setGMaps={setGMaps}
                rectangle={rectangle}
                setRectangle={setRectangle}
                onRectDrag={props.onRectDrag}
                getCenterWithOffset={getCenterWithOffset}
                zoomLevel={zoomLevel}
                setZoomLevel={setZoomLevel}
                setShowDocPanel={props.setShowDocPanel}
                setStreetLocation={setStreetLocation}
                setAutoScroll={setAutoScroll}
                resetJobMap={resetJobMap}
                context={context}
              />
            )}
          </div>
        </Col>
      )}
      {aiEarthState.isCollapse && (
        <OpenCollarse
          onClick={() => {
            dispatch(
              updateAIEarth({
                ...aiEarthState,
                ...{ isCollapse: false },
              })
            );
          }}
        >
          <ChevronsRight />
        </OpenCollarse>
      )}
    </Row>
  );
};

export default withAuthContext(AiEarthContainer);
