import React, { useState } from 'react';
import * as R from 'ramda';
import { Alert, Button, Spinner, Table } from 'react-bootstrap';
import styled from 'styled-components';
import { FileText } from 'react-feather';
import { toast } from 'react-toastify';
import { TIERS } from '../../types/tiers';
import { ref, getStorage, getDownloadURL } from 'firebase/storage';

const STR = styled.tr`
  position: sticky;
  top: 245px;
  background-color: #e9ecef;
`;
const RecordTR = styled.tr`
  && {
    background: #fff;
    background: var(--light);
  }
`;

const RecordTD = styled.td<any>`
  padding: 5px 0.75em;
  vertical-align: middle;
`;

const DownloadTD = styled(RecordTD)`
  display: flex;
  justify-content: center;
  cursor: pointer;
  button {
    width: 65px;
    height: 32px;
    padding: 2px 5px;
    font-size: 14px;
    svg {
      width: 20px;
      margin-right: 5px;
    }
    .spinner-border {
      width: 20px;
      height: 20px;
    }
  }
`;

const ContentTD = styled.td`
  && {
    padding: 0.75em;
  }
  em {
    font-style: normal;
    font-weight: 700;
  }
`;

interface IResult {
  records: any;
  context: any;
  queryText: string;
  isQuickFilter?: boolean;
}

/**
 * check if the file needs to be downloaded from storage bucket
 * or rely on the file url, directly.
 */
const doStorageDownload = R.contains(R.__, ['tceq_novell']);

const getStorageRef = R.cond<string, ReturnType<typeof ref> | null>([
  [R.equals('tceq_novell'), R.always(ref(getStorage(), `gs://${process.env.REACT_APP_STORAGE_BUCKET_NOVELL}`))],
  [R.T, R.always(null)],
]);
const getFileTypeText = (type: string) => {
  switch (type) {
    case 'pdf':
      return 'PDF';
    default:
      break;
  }
  return '';
};

const SearchResultTable: React.FC<IResult> = ({ records, context, queryText, isQuickFilter }) => {
  const [downloadTriggers, setDownloadTriggers] = useState<string[]>([]);
  const isDownloading = (contentId: string) => R.contains(contentId, downloadTriggers);

  return (
    <div>
      <Table id="searchResult" bordered hover>
        <thead>
          <STR>
            <th>Relevance Score</th>
            <th>Entity Name</th>
            <th>Type</th>
            <th>Release Date</th>
            <th>Category</th>
            <th>Regulatory ID</th>
            <th>File</th>
          </STR>
        </thead>
        <tbody>
          {records &&
            records.map((record: any, i: number) => {
              const isFileDownloading = isDownloading(record.contentId);
              return (
                <React.Fragment key={i}>
                  <RecordTR>
                    <RecordTD>{`${record.relevanceScore}%`}</RecordTD>
                    <RecordTD>{record.entityName}</RecordTD>
                    <RecordTD>{record.title}</RecordTD>
                    <RecordTD>{record.releaseDate}</RecordTD>
                    <RecordTD>{record.seriesName}</RecordTD>
                    <RecordTD>{record.entityNumber}</RecordTD>
                    <DownloadTD
                      onClick={() => {
                        if (!context.isAuthenticated) {
                          return toast.warn(
                            <span>
                              Please <Alert.Link href="/login">login</Alert.Link> to download files.
                            </span>,
                            {
                              position: toast.POSITION.BOTTOM_CENTER,
                            }
                          );
                        }
                        if (context.isTier(TIERS.FREE) && !context.isLubrizol()) {
                          return toast.warn(<span>Please upgrade to BASIC or PLATINUM to download files.</span>, {
                            position: toast.POSITION.BOTTOM_CENTER,
                          });
                        }
                        if (doStorageDownload(record.entitySource)) {
                          const storageRef = getStorageRef(record.entitySource);
                          if (R.isNil(storageRef)) {
                            return toast.error(`Oops, could not find the file.`);
                          }
                          setDownloadTriggers(R.append(record.contentId, downloadTriggers));
                          return getDownloadURL(ref(storageRef, record.fileName))
                            .then((url: any) => window.open(url, '_blank', 'noopener,noreferrer'))
                            .catch((err: any) => toast.error(err.message))
                            .finally(() => setDownloadTriggers(R.without([record.contentId], downloadTriggers)));
                        }
                        if (!record.fileUrl) {
                          return toast.warn(
                            <span>
                              No associated file was found for the content id <b>{record.contentId}</b>
                            </span>
                          );
                        }
                        window.open(record.fileUrl, '_blank');
                      }}
                    >
                      <Button variant="secondary" disabled={isFileDownloading}>
                        {isFileDownloading && <Spinner variant="light" animation="border" />}
                        {!isFileDownloading && (
                          <>
                            <FileText />
                            <span>{getFileTypeText(record.fileType)}</span>
                          </>
                        )}
                      </Button>
                    </DownloadTD>
                  </RecordTR>
                  <tr>
                    <ContentTD
                      colSpan={7}
                      dangerouslySetInnerHTML={{
                        __html:
                          record.hitContent.length > 0
                            ? record.hitContent
                                // Remove non-ascii chars.
                                .map((str: string) => {
                                  /*eslint no-control-regex: 0*/
                                  let wordGroup = '';
                                  queryText
                                    .trim()
                                    .split(' ')
                                    .map((srcWord: string) => {
                                      return (wordGroup += `<em>${srcWord.replace(/['",-]+/g, '')}</em>|`);
                                    });
                                  return isQuickFilter && queryText
                                    ? str.replace(/[^\x00-\x7F]/g, '').replace(new RegExp(wordGroup, 'gi'), (word) => {
                                        return word.replace(/<[^>]*>?/gm, '');
                                      })
                                    : str.replace(/[^\x00-\x7F]/g, '');
                                })
                                .join('<br />')
                            : '<h3>No Preview Available</h3>',
                      }}
                    />
                  </tr>
                </React.Fragment>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};
export default SearchResultTable;
