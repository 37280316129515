import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { IEnforcementDetailsModal } from '../../types/enforcement';
import { API_BASE_URL } from '../../util/api.util';

const IFrameContainer  = styled.div`
  position: relative;
  color: #007bff !important;

  iframe {
    margin-top: 36px;
  }

  .close-iframe {
    position: absolute;
    font-size: 1.5rem;
    top: 0;
    right: 0;
    color: #045fbc;
    border: none;
    background: none;
    cursor: pointer;
  }
`;

const EnforcementDetailsModal = ({ type, cnNumber, isOpen, onClose }: IEnforcementDetailsModal) => {
  return (
    <Modal
      show={isOpen}
      onHide={() => onClose(false)}
      size="lg"
      dialogClassName="enforcement-violation-details-modal"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zIndex: 99999 }}
      centered
      animation={false}
    >
      <Modal.Body>
        <IFrameContainer>
          <button className="close-iframe" onClick={() => onClose(false)}>X</button>
          <iframe title="iframe-title" width="100%" height="400" frameBorder="0" allowFullScreen src={`${API_BASE_URL}/BenchmarkingEnforcement?action=data_details&cn=${cnNumber}&type=${type}`}></iframe>
        </IFrameContainer>
      </Modal.Body>
    </Modal>
  );
};

export default EnforcementDetailsModal;