import { Button } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

export const TableContainer = styled.div`
  margin-top: 1rem;
`;
export const TD = styled.td`
  font-size: 14px;
  text-align: center;
  .link {
    text-decoration: underline;
  }
`;
export const TR = styled.tr`
  background-color: #e97132;
  color: #ffffff;
`;
export const TB = styled(Button)`
  background-color: #e97132;
  border-radius: 5px;
  border: 2px solid black;
  padding: 5px 80px;
  &:hover {
    color: #fff;
    background-color: #e97132;
    border-color: #000000;
  }
`;
export const TH = styled.th`
  font-size: 13px;
  text-align: center;
`;
export const TableHeading = styled.div`
  display: flex;
  gap: 20px;
  margin-left:35%;
  @media (max-width: 1440px) {
    && {
      margin-left:unset;
      justify-content:center;
    }
`;
export const BarHeading = styled.h6`
  font-family: Arial;
  text-align: center;
  font-size: 28px;
  color: #585757;
`;

export const StyledTooltip = styled(ReactTooltip)`
`;
