import React from 'react';
import { auth } from '../../services/firebase';
import { TIERS } from '../../types/tiers';
import { ICeresUser } from '../../interfaces/user';
import { createUserWithEmailAndPassword, GoogleAuthProvider, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, signOut, User, UserCredential } from 'firebase/auth';

export const authInitialState = {
  createUserWithEmailAndPassword: (email: string, password: string): Promise<UserCredential> =>
    createUserWithEmailAndPassword(auth, email, password),
  isAuthenticated: false,
  isInitialized: false,
  claims: null,
  isTier: (tier: TIERS) => tier === authInitialState.getUserTier(),
  isLubrizol: () => false,
  getUserTier: () => TIERS.FREE,
  getUserEmail: () => '',
  getUserName: () => '',
  getUserPhone: () => '',
  getClientName: () => '',
  getClientAddress: () => '',
  getUserDisplayName: () => '',
  getUserId: () => '',
  sendPasswordResetEmail: (email: string) => sendPasswordResetEmail(auth, email),
  signInWithEmailAndPassword: (email: string, password: string): Promise<UserCredential> =>
    signInWithEmailAndPassword(auth, email, password),
  signInWithGoogle: (): Promise<UserCredential> => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  },
  signInWithSSO: () => Promise.reject('SSO not implemented'),
  signOut: () => signOut(auth),
  user: null,
  getCompleteUserInfo: () => null,
};

const AuthContext = React.createContext<IAuthContext>(authInitialState);
export interface IAuthContext {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: User | null;
  claims: any;
  signInWithGoogle: () => Promise<UserCredential>;
  signInWithSSO: () => Promise<UserCredential>;
  createUserWithEmailAndPassword: (email: string, password: string) => Promise<UserCredential>;
  sendPasswordResetEmail: (email: string) => Promise<void>;
  signOut: () => Promise<void>;
  isTier: (tier: TIERS) => boolean;
  isLubrizol: () => boolean;
  getUserTier: () => TIERS;
  getUserEmail: () => string;
  getUserName: () => string;
  getUserPhone: () => string;
  getClientName: () => string;
  getClientAddress: () => string;
  getUserDisplayName: () => string;
  getUserId: () => string;
  getCompleteUserInfo: () => ICeresUser | null;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<UserCredential>;
}

export default AuthContext;
