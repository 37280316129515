import React, { useEffect, useState } from 'react';
import { db } from '../../services/firebase';
import NotificationContext, {
  INotificationContext,
  notificationInitialState
} from './notifications-context';
import { onSnapshot, query, collection, limit } from 'firebase/firestore';

interface INotificationProviderProps {
  uid: string;
}

const NotificationProvider: React.FC<INotificationProviderProps> = props => {
  const [state, setState] = useState<INotificationContext>(
    notificationInitialState
  );

  useEffect(() => {
    if (!props.uid) {
      return;
    }
    const unsubscribeNotifications = onSnapshot(
      query(
        collection(db, 'users', props.uid, 'notifications'),
        limit(100)
      ),
      (snapshot) => {
        const docs = snapshot.docs;
        setState({
          notifications: docs
            .map((c) => ({
              ...c.data(),
              id: c.id,
            }))
            .filter((c: any) => c.subscriptionId),
        });
      },
      (error: any) => {
        // tslint:disable-next-line:no-console
        console.error('Error fetching notifications:', error);
      }
    );
    return () => unsubscribeNotifications();
  }, [props.uid]);

  return (
    <NotificationContext.Provider value={state}>
      {props.children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
