import React, { useState } from 'react';
import { Alert, Button, Form, FormControl, FormGroup, InputGroup, Spinner } from 'react-bootstrap';
import { Lock, Mail } from 'react-feather';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import GoogleIconSvg from '../assets/google-icon.svg';
import { IAuthContext } from '../context/auth/auth-context';
import withAuthContext from '../context/auth/AuthConsumer';
import { Link } from 'react-router-dom';
import { GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { auth } from '../services/firebase';

const LoginFormGroup = styled(FormGroup)`
  margin-bottom: 2rem;
`;

const LoginTitle = styled.h3`
  color: rgb(44, 25, 14);
  text-align: center;
  font-weight: 500;
  padding: 10px;
  font-size: 20px;
  text-transform: uppercase;
`;

const FullWidthButton = styled(Button)`
  width: 100%;
  margin-bottom: 2rem;
`;
const SignInButton = styled<any>(FullWidthButton)`
  && {
    color: rgb(44, 25, 14);
    background-color: rgb(221, 197, 179);
    border-color: rgb(221, 197, 179);
  }
  &:active {
    color: rgb(44, 25, 14) !important;
    background-color: rgb(221, 197, 179) !important;
    border-color: rgb(221, 197, 179) !important;
  }
  &:focus {
    box-shadow: unset !important;
  }
`;
const SignUpButton = styled<any>(FullWidthButton)`
  && {
    border-color: rgb(221, 197, 179);
    color: rgb(44, 25, 14);
    &:hover, &:active {
      color: #fff !important;
      background-color: rgb(44, 25, 14) !important;
      border-color: rgb(44, 25, 14) !important;
    }
    &:focus {
      box-shadow: unset !important;
    }
  }
`;
const LoginFormContainer = styled.div`
  width: 300px;
`;
const GoogleIcon = styled.img`
  vertical-align: bottom;
  margin-right: 1em;
`;
const PasswordFormGroup = styled<any>(LoginFormGroup)`
  text-align: right;
  margin-bottom: 1.5rem;
  a {
    top: 5px;
    position: relative;
    color: rgb(44, 25, 14);
  }
`;

const SocialButtons = styled.div`
  text-align: center;
  color: #6c757d;
  color: var(--secondary);
`;
const SocialButtonText = styled.div`
  margin-bottom: 15px;
`;

interface ILoginFormProps {
  onSuccess: (user: string) => void;
  onLoginFail?: (err: string) => void;
}

const LoginForm: React.FC<ILoginFormProps & { context: IAuthContext }> = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSSOLoading, setIsSSOLoading] = useState(false);
  const [isLoginFail, setIsLoginFail] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [redirectToSignup, setRedirectToSignup] = useState(false);
  const context = props.context;
  if (redirectToSignup) {
    return <Redirect to={{ pathname: '/signup' }} />;
  }
  const provider = new GoogleAuthProvider();
  return (
    <LoginFormContainer>
      <Form
        onSubmit={async (e: React.FormEvent) => {
          e.preventDefault();
          setIsLoading(true);
          setIsLoginFail(false);

          signInWithEmailAndPassword(auth, email, password).then(async (userCredential) => {
            if (userCredential.user && userCredential.user.emailVerified) {
              const idToken = await userCredential.user.getIdToken();
              const expiresIn = 60 * 60 * 24 * 5 * 1000; // 5 days in milliseconds
              const expiresDate = new Date(Date.now() + expiresIn).toUTCString();
              document.cookie = `session=${idToken}; path=/; domain=.searchtundra.com; Secure; SameSite=Lax; expires=${expiresDate}`;
              return props.onSuccess((userCredential.user.uid) || '');
            } else {
              setIsLoginFail(true);
              setIsLoading(false);
              setLoginError('Please verify the email');
              props.onLoginFail && props.onLoginFail('Please Verify the email');
            }
          }).catch((err: any) => {
            setIsLoginFail(true);
            setIsLoading(false);
            setLoginError('Invalid Email or Password');
            props.onLoginFail && props.onLoginFail(err.message);
          });
        }}
      >
        {isLoginFail && (
          <Alert dismissible variant="danger" onClose={() => setIsLoginFail(false)}>
            <Alert.Heading>Login Error</Alert.Heading>
            <p>{loginError}</p>
          </Alert>
        )}
        <LoginFormGroup>
          <LoginTitle>Sign In</LoginTitle>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <Mail />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={(e: any) => setEmail(e.target.value)}
              placeholder="Email"
              type="email"
              name="email"
              required
              disabled={isLoading}
            />
          </InputGroup>
        </LoginFormGroup>
        <PasswordFormGroup>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <Lock />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={(e: any) => setPassword(e.target.value)}
              placeholder="Password"
              type="password"
              name="password"
              required
              disabled={isLoading}
            />
          </InputGroup>
          <Link to="/forgot-password">Forgot Password?</Link>
        </PasswordFormGroup>
        <SignInButton variant="primary" type="submit" disabled={isLoading}>
          {!isLoading && 'Login'}
          {isLoading && <Spinner animation="border" variant="light" size="sm" />}
        </SignInButton>
        <SignUpButton variant="outline-primary" onClick={() => setRedirectToSignup(true)} disabled={isLoading}>
          Sign Up
        </SignUpButton>
        <SocialButtons>
          <SocialButtonText>or you can login with</SocialButtonText>
          <SignUpButton
            variant="outline-primary"
            onClick={() => {
              return signInWithPopup(auth, provider)
                .then((userCredential) => {
                  return props.onSuccess((userCredential.user && userCredential.user.uid) || '');
                });
            }}
            disabled={isLoading}
          >
            <GoogleIcon src={GoogleIconSvg} />
            {!isLoading && 'Login with Google'}
            {isLoading && <Spinner animation="border" variant="primary" size="sm" />}
          </SignUpButton>
          <SignUpButton
            variant="outline-primary"
            onClick={() => {
              context.signInWithSSO()
              .then(async (userCredential) => {
                setIsSSOLoading(true);
                await new Promise((resolve) => setTimeout(resolve, 2000));
                return props.onSuccess((userCredential.user && userCredential.user.uid) || '');
              })
              .catch((error) => {
                setIsSSOLoading(false);
                setLoginError(`Couldn't able to process the login request`);
                props.onLoginFail && props.onLoginFail(error.message);
              });
            }}
          >
            {!isSSOLoading && 'Login with SSO'}
            {isSSOLoading && <Spinner animation="border" variant="primary" size="sm" />}
          </SignUpButton>
        </SocialButtons>
      </Form>
    </LoginFormContainer>
  );
};

export default withAuthContext(LoginForm);
