export const enforcementChartLabels = ['NOVs', 'NOEs', 'Agreed Orders', 'Complaints'];
export const backgroundColors = ['#156082', '#E97132', '#196B24', '#8B4513', '#4682B4'];
export const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    position: 'bottom',
    labels: {
      fontSize: 14,
      boxWidth: 20,
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          stepSize: 2,
          beginAtZero: true,
          fontSize: 18,
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          fontSize: 18,
        },
      },
    ],
  },
};
export const tableColumns = [
  { key: 'CNName', heading: '' },
  { key: 'Year', heading: 'Year'},
  { key: 'NOVs', heading: 'NOVs' },
  { key: 'NOEs', heading: 'NOEs' },
  { key: 'CatA', heading: 'Cat. A Violations' },
  { key: 'CatB', heading: 'Cat. B Violations' },
  { key: 'CatC', heading: 'Cat. C Violations' },
  { key: 'NumberofViolations', heading: 'Number of Violations' },
  { key: 'Orders', heading: 'Agreed Orders' },
  { key: 'Complaints', heading: 'Complaints' },
  { key: 'assessedAmount', heading: 'Dollar Amount Assessed' },
];
