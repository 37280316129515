import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ISearchRecord, IElasticResult } from '../../../types/search';
import { search } from '../../../services/firebase';
import { Spinner } from 'react-bootstrap';
import * as R from 'ramda';
import { X } from 'react-feather';
import withAuthContext from '../../../context/auth/AuthConsumer';
import { IAuthContext } from '../../../context/auth/auth-context';
import { TIERS } from '../../../types/tiers';
import { toast } from 'react-toastify';
import { ref, getStorage, getDownloadURL } from 'firebase/storage';

interface IRecordMarkerProps {
  lat: number;
  lng: number;
  isActive: boolean;
  record: ISearchRecord;
  onMarkerClick: () => void;
  onInfoWindowClose: () => void;
  context: IAuthContext;
}
const RecordMarkerInfoWindowContainer = styled.div`
  position: absolute;
  bottom: 30px;
  left: -250px;
  width: 500px;
  background: #fff;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
  padding: 10px;
  font-size: 14px;
  z-index: 100;
`;

const EntityRecordListContainer = styled.div`
  height: 250px;
  overflow-y: scroll;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em 0em;
`;

const MarkerPin = styled.div<IProps>`
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  background: #005fbc;
  background: var(--primary);
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  cursor: pointer;
  z-index: 10;
  background: ${(props) => props.isActive};
  &:after {
    content: '';
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: #fff;
    background: var(--light);
    position: absolute;
    border-radius: 50%;
  }
`;

interface IProps {
  isActive?: string;
}
const getTCEQRecordUrl = (entityNumber: string) =>
  `https://www15.tceq.texas.gov/crpub/index.cfm?fuseaction=regent.validateRE&re_ref_num_txt=${entityNumber}`;

const fetchRecordsByRN = (rnNumber: string) => {
  return search({
    query: {
      from: 0,
      size: 50,
      queryText: '',
      isLocationRemove: true,
      terms: {
        regulated_entity_number: rnNumber,
      },
    },
  });
};

const InfoWindowCloseContainer = styled.div`
  position: absolute;
  right: 10px;
  cursor: pointer;
`;

const DownloadHyperlink = styled.a`
  color: #005fbc;
  cursor: pointer;
  &:hover {
    color: #ac5e08 !important;
    text-decoration: underline !important;
  }
`;

const RecordMarkerContainer = styled.div``;

/**
 * check if the file needs to be downloaded from storage bucket
 * or rely on the file url, directly.
 */
const doStorageDownload = R.contains(R.__, ['tceq_novell']);

const getStorageRef = R.cond<string, ReturnType<typeof ref> | null>([
  [R.equals('tceq_novell'), R.always(ref(getStorage(), `gs://${process.env.REACT_APP_STORAGE_BUCKET_NOVELL}`))],
  [R.T, R.always(null)],
]);

const RecordMarkerInfoWindow: React.FC<{
  record: ISearchRecord;
  isLoading: boolean;
  relatedRecords: ISearchRecord[];
  onInfoWindowClose: () => void;
  authContext: IAuthContext;
}> = (props) => {
  const authContext = props.authContext;
  const record = props.record;
  const renderSpinner = () => (
    <SpinnerContainer>
      <Spinner animation="border" variant="primary" />
    </SpinnerContainer>
  );
  const getRecordTitle = (item: ISearchRecord) => `${item.title} - ${item.seriesName} - ${item.releaseDate}`;
  const renderResult = () => {
    return (
      <EntityRecordListContainer>
        <h6>
          {authContext.isTier(TIERS.FREE) && !authContext.isLubrizol() ? (
            record.entityNumber
          ) : (
            <a target="_blank" rel="noopener noreferrer" href={getTCEQRecordUrl(record.entityNumber)}>
              {record.entityNumber}
            </a>
          )}
        </h6>
        <ol>
          {R.compose(
            R.map((relatedRecord: ISearchRecord) => {
              return (
                <li key={relatedRecord.contentId}>
                  {authContext.isTier(TIERS.FREE) && !authContext.isLubrizol() ? (
                    getRecordTitle(relatedRecord)
                  ) : (
                    <DownloadHyperlink
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (doStorageDownload(relatedRecord.entitySource)) {
                          const storageRef = getStorageRef(relatedRecord.entitySource);
                          if (R.isNil(storageRef)) {
                            return toast.error(`Oops, could not find the file.`);
                          }
                          return getDownloadURL(ref(storageRef, relatedRecord.fileName))
                            .then((url) => window.open(url, '_blank', 'noopener,noreferrer'))
                            .catch((err) => toast.error(err.message));
                        }
                        if (!relatedRecord.fileUrl) {
                          return toast.warn(
                            <span>
                              No associated file was found for the content id <b>{relatedRecord.contentId}</b>
                            </span>
                          );
                        }
                        window.open(relatedRecord.fileUrl, '_blank');
                      }}
                    >
                      {getRecordTitle(relatedRecord)}
                    </DownloadHyperlink>
                  )}
                </li>
              );
            }),
            R.sort(
              R.comparator(
                (itemOne: ISearchRecord, itemTwo: ISearchRecord) => itemOne.releaseDate > itemTwo.releaseDate
              )
            )
          )(props.relatedRecords)}
        </ol>
      </EntityRecordListContainer>
    );
  };
  return (
    <RecordMarkerInfoWindowContainer>
      <InfoWindowCloseContainer onClick={() => props.onInfoWindowClose()}>
        <X />
      </InfoWindowCloseContainer>
      <h4>{record.entityName}</h4>
      {props.isLoading && renderSpinner()}
      {!props.isLoading && renderResult()}
    </RecordMarkerInfoWindowContainer>
  );
};

const RecordMarker: React.FC<IRecordMarkerProps> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [relatedRecords, setRelatedRecords] = useState<ISearchRecord[]>([]);
  const entityNumber = props.record.entityNumber;
  const authContext = props.context;
  useEffect(() => {
    let isSubscribed = true;
    fetchRecordsByRN(entityNumber)
      .then((result) => {
        const data = result.data as IElasticResult<ISearchRecord>;
        const records = (data && data.records) || [];
        isSubscribed && setRelatedRecords(records);
      })
      .finally(() => isSubscribed && setIsLoading(false));
    return () => {
      isSubscribed = false;
    };
  }, [entityNumber]);
  return (
    <RecordMarkerContainer>
      <MarkerPin
        isActive={props.isActive ? '#336195' : '#005FBC'}
        onClick={(e) => {
          props.onMarkerClick();
          e.stopPropagation();
        }}
      />
      {props.isActive && (
        <RecordMarkerInfoWindow
          authContext={authContext}
          onInfoWindowClose={props.onInfoWindowClose}
          record={props.record}
          isLoading={isLoading}
          relatedRecords={relatedRecords}
        />
      )}
    </RecordMarkerContainer>
  );
};

export default withAuthContext(RecordMarker);
