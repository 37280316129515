import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
// @ts-ignore
import createFilterOptions from 'react-select-fast-filter-options';

import queryString from 'query-string';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';

import { IAuthContext } from '../context/auth/auth-context';
import withAuthContext from '../context/auth/AuthConsumer';
import PaperContainer from '../components/PaperContainer';
import { CeresLogger } from '../logger';
import './react-select.css';
import Emission from '../components/benchmarking/Emission';
import NoRecordImage from '../assets/no-record-img.png';
import { BenchmarkWrapper, BenchmarkContainer, CenteredContainer } from '../components/custom-styled-components/BechmarkingStyledComponents';
import { CorporateSelect, ResultContainer, HeaderRow } from '../components/custom-styled-components/CommonStyledComponents';
import { emissionType, emissionTypeConstants } from '../util/benchmarking.util';
import { API_BASE_URL, API_AUTH_HEADERS } from '../util/api.util';

const Benchmarking: React.FC<
  RouteComponentProps<any> & {
    context: IAuthContext;
  }
> = (props) => {
  const [entity, setEntity] = useState<any>();
  const [entityType, setEntityType] = useState<any>();
  const [filter, setFilter] = useState();
  const [statsResult, setStatsResult] = useState<any>();
  const [statsCnResults, setStatsCnResults] = useState<any>();
  const [isDisabled, setIsDisabled] = useState(true);
  const [resultRecords, setResultRecords] = useState<any>();
  const [noRecords, setNoRecords] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cnNumber, setCnNumber] = useState('');

  useEffect(() => {
    getCorporates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (window.top !== window.self && resultRecords) {
      const filterData = queryString.parse(props.history.location.search);
      if (filterData.rn) {
        const entityFilterRecord = searchParam(resultRecords, filterData.rn) || filterData.rn;
        const entityFilterType = emissionType[0];

        setEntity(entityFilterRecord);
        setEntityType(entityFilterType);

        if (typeof entityFilterRecord === 'object') {
          fetchRNStats(entityFilterType.value, entityFilterRecord.value);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultRecords]);

  const fetchRNStats = async (type: string, value: any) => {
    setIsDisabled(true);
    setLoading(true);
    // TODO: Instead of type being a number we need to name correctly
    const apiEndpoints: Record<string, string> = {
      [emissionTypeConstants.EMISSIONS_EVENTS]: `${API_BASE_URL}/api/fetchRNStats`,
      [emissionTypeConstants.ANNUAL_FACILITY_EMISSIONS]: `${API_BASE_URL}/api/fetchAirFacilityEmissionsNStats`,
      [emissionTypeConstants.ENFORCEMENT]: `${API_BASE_URL}/BenchmarkingEnforcement?action=rn_details&rn=${value}`,
    };

    const emissionApiUrl = apiEndpoints[type];
    if (!emissionApiUrl) {
      setIsDisabled(false);
      setLoading(false);
      return;
    }

    try {
      if (type !== emissionTypeConstants.ENFORCEMENT) {
        const response = await axios.post(emissionApiUrl, { RN: value }, {
          headers: API_AUTH_HEADERS,
        });
        setStatsResult(response.data[0] || '');
        setNoRecords(!response.data.length);
      } else {
        const response = await axios.get(emissionApiUrl);
        if (response.data.data.length) {
          const cNumber = response.data.data[0]['CN Number'];
          setCnNumber(cNumber);
          setStatsResult(response.data.data[0].enforcement_list);
          setNoRecords(false);

          const cnResponse = await axios.get(
            API_BASE_URL +`/BenchmarkingEnforcement?action=cn_details&rn=${value}&cn=${cNumber}`
          );

          if (cnResponse.data.data.length) {
            const enforcementData = cnResponse.data.data[0].enforcement_list;
            enforcementData.cnName = cnResponse.data.data[0]['CN Name'];
            setStatsCnResults(enforcementData);
          }
        } else {
          setNoRecords(true);
        }
      }
    } catch (error: any) {
      setNoRecords(true);
      setStatsResult('');
      setStatsCnResults('');
      toast.error(<span>Error! No record found for <b>{value}</b></span>);
      CeresLogger.error(error);
    } finally {
      setIsDisabled(false);
      setLoading(false);
    }
  };

  const getCorporates = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/eventEmissions/corporates`,
        // 'http://localhost:5000/ceres-platform-test/us-central1/api/eventEmissions/corporates',
        {
          headers: API_AUTH_HEADERS,
        }
      );

      const records = response.data[0].jsonlistb || [];
      setResultRecords(records);
      setFilter(() => createFilterOptions({ options: records }));

      const filterData: any = queryString.parse(props.history.location.search);
      if (filterData.rn && filterData.event) {
        const entityFilterRecord = searchParam(records, filterData.rn);
        const entityFilterType = searchParam(emissionType, parseInt(filterData.event, 10));
        setEntity(entityFilterRecord);
        setEntityType(entityFilterType);
        await fetchRNStats(entityFilterType.value, entityFilterRecord.value);
      }
    } catch (error: any) {
      CeresLogger.error(error);
    } finally {
      setIsDisabled(false);
    }
  };

  const searchParam = (data: any[], value: any): any | undefined => {
    return data.find((aData) => aData.value === value);
  };

  return (
    <BenchmarkWrapper>
      <BenchmarkContainer>
        <HeaderRow>
          {!!isDisabled && (
            <>
              <div>
                {/* Fetching Corporates{' '} */}
                <Spinner animation="border" variant="dark" size="sm" />
              </div>
            </>
          )}
        </HeaderRow>
        <ResultContainer>
          <Row>
            <Col md={8}>
              <CorporateSelect
                value={entity}
                className="basic-single"
                filterOptions={filter}
                disabled={isDisabled}
                name="corporate1"
                options={resultRecords}
                onChange={(e: any) => {
                  setEntity(e);
                  if (e && entityType) {
                    fetchRNStats(entityType.value, e.value);
                  } else {
                    return null;
                  }
                }}
              />
            </Col>
            <Col md={4}>
              <CorporateSelect
                value={entityType}
                className="basic-single"
                filterOptions={filter}
                disabled={isDisabled}
                name="events"
                options={emissionType}
                onChange={(e: any) => {
                  setEntityType(e);
                  if (!e) {
                    return;
                  }
                  if (!entity) {
                    toast.info('Please select an Entity!');
                    return;
                  }
                  fetchRNStats(e.value, entity.value);
                }}
              />
            </Col>
          </Row>
        </ResultContainer>
        {loading && (
          <CenteredContainer>
            <Spinner animation="border" variant="primary" />
          </CenteredContainer>
        )}

        {!loading && noRecords && (
          <CenteredContainer>
            <div style={{ textAlign: 'center' }}>
              <img src={NoRecordImage} alt="No Records Found" />
            </div>
          </CenteredContainer>
        )}

        {!loading && (
          <PaperContainer>
            {statsResult && entity && entityType && (
              <ResultContainer>
                <Emission result={statsResult} cnNumber={cnNumber} entity={entity} types={entityType} cnStatsResult={statsCnResults} />
              </ResultContainer>
            )}
          </PaperContainer>
        )}
      </BenchmarkContainer>
    </BenchmarkWrapper>
  );
};
export default withAuthContext(Benchmarking);
