import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AppSpinner from './components/AppSpinner';
import { IAuthContext } from './context/auth/auth-context';
import withAuthContext from './context/auth/AuthConsumer';
import Page404 from './pages/404';
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login';
import Shell from './pages/Shell';
import Signup from './pages/Signup';
import ReactGA from 'react-ga4';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE || '');


const usePageTracking = () => {
  useEffect(() => {
    const pagePath = window.location.pathname + window.location.search;
    ReactGA.send({ hitType: 'pageview', page: pagePath });
  }, []);
};

const App: React.FC<{ context: IAuthContext }> = ({
  context
}: {
  context: IAuthContext;
}) => {
  useEffect(() => {
    // tslint:disable-next-line:no-console
    console.log('TUNDRA APP');
  });
  usePageTracking();
  if (!context.isInitialized) {
    return <AppSpinner />;
  }
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route
          path="/"
          component={Shell}
        />
        <Route component={Page404} />
      </Switch>
    </Router>
  );
};

export default withAuthContext(App);
