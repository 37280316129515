import React from 'react';
import styled from 'styled-components';
import AirEmission from './AirEmission';
import EmissionEvent from './EmissionEvent';
import EnforcementEvent from './Enforcement';
import { emissionTypeConstants } from '../../util/benchmarking.util';
import { IEmissions } from '../../types/enforcement';

const Container = styled.div``;

const Emissions: React.FC<IEmissions> = (props) => {
  const statsResult = props.result;
  const entity = props.entity;
  const types = props.types;
  const cnResults = props.cnStatsResult;
  const cnNumber = props.cnNumber;
  return (
   <Container>
      {
         (types.value === emissionTypeConstants.EMISSIONS_EVENTS) &&
           statsResult &&
           (typeof statsResult.bm_emission_json !== 'undefined') && (
          <EmissionEvent result={statsResult.bm_emission_json} entity={entity} />
        )
      }
      {
         (types.value === emissionTypeConstants.ANNUAL_FACILITY_EMISSIONS) &&
           statsResult &&
           (typeof statsResult.bm_annual_facility_emissions_json !== 'undefined') && (
        <AirEmission result={statsResult.bm_annual_facility_emissions_json} entity={entity} />
      )
      }
      { (types.value === emissionTypeConstants.ENFORCEMENT) && <EnforcementEvent result={statsResult} entity={entity} cnNumber={cnNumber} cnStatsResult={cnResults}/> }
   </Container>
  );
};

export default Emissions;
