import { initializeApp } from 'firebase/app';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import * as R from 'ramda';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import { CeresLogger } from '../logger';
import * as CryptoJS from 'crypto-js';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref } from 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  appId: process.env.REACT_APP_ID,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
};

export const ceresApp = initializeApp(firebaseConfig);
const functions = getFunctions(ceresApp);

if (R.equals(process.env.REACT_APP_USE_FUNCTIONS_SIMULATOR, 'true')) {
  const functionSimulatorUrl = process.env.REACT_APP_SIMULATOR_URL || 'http://localhost:5000';
  // tslint:disable-next-line
  CeresLogger.info({
    message: `Using local function simulator. url: ${functionSimulatorUrl}`,
  });
  connectFunctionsEmulator(functions, 'localhost', 5000);
}

export const auth = getAuth(ceresApp);
export const db = getFirestore(ceresApp);
export const searchFavorite = httpsCallable(functions, 'searchFavorite');
export const search = httpsCallable(functions, 'search');
export const searchLDEQ = httpsCallable(functions, 'searchLDEQ');
export const searchAIEarth = httpsCallable(functions, 'searchAIEarth');
export const customSearch = httpsCallable(functions, 'customSearch');
export const placeTextSearch = httpsCallable(functions, 'placeTextSearch');
export const buttonFavorite = httpsCallable(functions, 'buttonFavorite');
export const eagleEye = httpsCallable(functions, 'getImageQuality');
export const fetchCeresUsers = httpsCallable(functions, 'fetchCeresUsers');
export const fetchValidatorUsage = httpsCallable(functions, 'fetchValidatorUsage');
export const updateUser = httpsCallable(functions, 'updateUser');
export const chatBotChat = httpsCallable(functions, 'chatBotChat');
export const stripeCheckout = httpsCallable(functions, 'stripe-checkout');
export const addJob = httpsCallable(functions, 'addJob');
export const addEagleEyeJob = httpsCallable(functions, 'addEagleEyeJob');
export const saveChats = httpsCallable(functions, 'savedChats');
export const saveProfile = httpsCallable(functions, 'savedProfile');
export const savePrompts = httpsCallable(functions, 'savedPrompts');
export const saveLibrary = httpsCallable(functions, 'savedLibrary');

// Storage
export const storage = getStorage(ceresApp);
export const validatorStorage = ref(storage, `gs://${process.env.REACT_APP_VALIDATOR_STORAGE_BUCKET}`);
export const eagleEyeStorage = ref(storage, `gs://${process.env.REACT_APP_EAGLEEYE_STORAGE_BUCKET}`);

// Project Configuration
export const projectId = process.env.REACT_APP_PROJECT_ID;
export const key = CryptoJS.enc.Utf8.parse('0fab98deb90c6d7b122832b617c2a8b7');
export const iv = CryptoJS.enc.Utf8.parse('A58E47EE52124CFD');
